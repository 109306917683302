import React from 'react';
import { TokenAccount } from '../../utils/types';
import { useSelectedTokenAccounts } from '../../utils/markets';
import { Button, Col, Select, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { abbreviateAddress } from '../../utils/utils';
import { notify } from '../../utils/notifications';
import {
  ContentWrapper,
  ContentDetails,
  TopContent,
  Heading,
  Hr,
  Info,
  MiddleContent,
  BottomContent,
  TextContent,
  Text,
  ButtonWrapper,
  CopyText,
  Description,
  Deposit,
  Settle,
  AllWallLinK,
  Span,
} from './styles';
import CopyImg from '../../assets/copy-text.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
export default function StandaloneTokenAccountsSelect({
  accounts,
  mint,
  label,
}) {
  const [selectedTokenAccounts, setSelectedTokenAccounts] =
    useSelectedTokenAccounts();

  let selectedValue;
  if (mint && mint in selectedTokenAccounts) {
    selectedValue = selectedTokenAccounts[mint];
  } else if (accounts && accounts?.length > 0) {
    selectedValue = accounts[0].pubkey.toBase58();
  } else {
    selectedValue = undefined;
  }

  const setTokenAccountForCoin = (value) => {
    if (!mint) {
      notify({
        message: 'Error selecting token account',
        description: 'Mint is undefined',
        type: 'error',
      });
      return;
    }
    const newSelectedTokenAccounts = { ...selectedTokenAccounts };
    newSelectedTokenAccounts[mint] = value;
    setSelectedTokenAccounts(newSelectedTokenAccounts);
  };

  return (
    <React.Fragment>
      {/* {label && <Col span={8}>Token account:</Col>}
      <Col span={label ? 13 : 21}>
        <Select
          style={{ width: '100%' }}
          value={selectedValue}
          onSelect={setTokenAccountForCoin}
        >
          {accounts?.map((account) => (
            <Select.Option
              key={account.pubkey.toBase58()}
              value={account.pubkey.toBase58()}
            >
              <Typography.Text code>
                {label
                  ? abbreviateAddress(account.pubkey, 8)
                  : account.pubkey.toBase58()}
              </Typography.Text>
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={2} offset={1}>
        <Button
          shape="round"
          icon={<CopyOutlined />}
          size={'small'}
          onClick={() =>
            selectedValue && navigator.clipboard.writeText(selectedValue)
          }
        />
      </Col> */}
      <TextContent>
        <Text>token account</Text>
        <ButtonWrapper>
        <CopyToClipboard
                          onCopy={() => alert('copied')}
                          text={selectedValue}
                        >
                         <CopyText src={CopyImg} alt="" style={{cursor:'pointer'}} />
                        </CopyToClipboard>

         
          <Select
            value={selectedValue}
            style={{
              marginRight: 8,
              width: '107px',
              fontSize: '12px',
              fontWeight: '700',
              textTransform: 'uppercase',
            }}
          >
            {accounts?.map((account) => (
              <Select.Option
                key={account.pubkey.toBase58()}
                value={account.pubkey.toBase58()}
                style={{
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  padding: '10px 16px',
                }}
              >
                {label
                  ? abbreviateAddress(account.pubkey, 8)
                  : account.pubkey.toBase58()}
              </Select.Option>
            ))}
          </Select>
        </ButtonWrapper>
      </TextContent>
    </React.Fragment>
  );
}
