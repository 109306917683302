import {Connection, PublicKey} from "@solana/web3.js";
import {PROGRAM_DEX} from "./config";
import {Market} from "@charles-seteda/sb-js";
import {useRecentMarketTrades} from "./markets";
import {sleep} from "./utils";

export async function getCurrentRecentTrade(connection: Connection, marketAddress: String) {
    let ret = [];
    try {
        const limit = 100;   // Number of placeOrder will be fetched
        const programId = new PublicKey(PROGRAM_DEX);
        const market = await Market.load(
            connection,
            new PublicKey(marketAddress),
            {},
            programId
        );
        const rawTrades = await market.loadFills(connection, limit);
        await sleep(1000);
        let dataTrade: any = [];
        rawTrades.map((item) => {
            if (item.eventFlags.maker) {
                const newData = {
                    eventFlags: item.eventFlags,
                    side: item.side,
                    time: new Date(),
                    timeUpdate: new Date(),
                    owner: null,
                    market: marketAddress,
                    size: item.size,
                    price: item.price,
                    priceLots: item.priceLots,
                    sizeLots: item.sizeLots,
                    feeCost: item.feeCost,
                    marketAddress: marketAddress,
                    orderId: item.orderId.toString()
                };
                dataTrade.push(newData);
            }
        });
        if (dataTrade && dataTrade.length > 0) {
            dataTrade.sort((a:any, b:any) => {
                if (a.orderId > b.orderId) return 1;
                if (a.orderId < b.orderId) return -1;
                return 0;
            });
        }
        ret = dataTrade;
    } catch (e) {
        console.log(e);
    }
    return ret;
}

const RecentTrade = async (connection: Connection, marketAddress: String) => {
    const {setRecentMarketTrades} = useRecentMarketTrades();
    let ret = [];
    try {
        const limit = 100;   // Number of placeOrder will be fetched
        const programId = new PublicKey(PROGRAM_DEX);
        const market = await Market.load(
            connection,
            new PublicKey(marketAddress),
            {},
            programId
        );
        const callBackData = async function (updatedAccountInfo, context) {
            const owner = updatedAccountInfo.owner.toBase58();
            const rawTrades = await market.loadFills(connection, limit);
            let dataTrade: any = [];
            rawTrades.map((item) => {
                if (item.eventFlags.maker) {
                    const newData = {
                        eventFlags: item.eventFlags,
                        side: item.side,
                        time: new Date(),
                        timeUpdate: new Date(),
                        owner: owner,
                        market: marketAddress,
                        size: item.size,
                        price: item.price,
                        priceLots: item.priceLots,
                        sizeLots: item.sizeLots,
                        feeCost: item.feeCost,
                        marketAddress: marketAddress,
                        orderId: item.orderId.toString()
                    };
                    dataTrade.push(newData);
                }
            });
            if (dataTrade && dataTrade.length > 0) {
                dataTrade.sort((a:any, b:any) => {
                    if (a.orderId > b.orderId) return 1;
                    if (a.orderId < b.orderId) return -1;
                    return 0;
                });
            }
            console.log({dataTrade: dataTrade});
            setRecentMarketTrades(dataTrade);
        };
        if (market.bidsAddress) {
            connection.onAccountChange(
                market.bidsAddress,
                callBackData,
                "confirmed"
            );
        }
    } catch (e) {
        console.log(e);
    }
    return ret;
}

// export default RecentTrade;