import React, { useEffect } from 'react';
import { Container } from './style';
import {
  useMarket,
  useSbMetalendTrades,
  useRecentMarketTrades,
  _SLOW_REFRESH_INTERVAL,
} from '../../utils/markets';
import { getDecimalCount } from '../../utils/utils';
import dayjs from '../../utils/dayjs';
//import {SbMetalendTrade} from '../../utils/types';
import { useConnection } from '../../utils/connection';
import { getCurrentRecentTrade } from '../../utils/socketApi';

const TradeTable = () => {
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const { recentMarketTrades, setRecentMarketTrades } = useRecentMarketTrades();
  // const [trades, loaded] = useSbMetalendTrades();

  const marketAddress = market?.address.toBase58();
  const connection = useConnection();
  useEffect(() => {
    if (marketAddress) {
      const fetchData = async () => {
        setRecentMarketTrades(
          await getCurrentRecentTrade(connection, marketAddress),
        );
      };
      fetchData().catch(console.error);
    }
  }, [marketAddress]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (marketAddress) {
        const fetchData = async () => {
          setRecentMarketTrades(
            await getCurrentRecentTrade(connection, marketAddress),
          );
        };
        fetchData().catch(console.error);
      }
    }, _SLOW_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [marketAddress]);

  return (
    <Container>
      <div className="topbar">
        <h4 className="header">Recent market trades</h4>
        <div className="tableHeading">
          <p className="left-header">Price ({quoteCurrency})</p>
          <p className="center-header">size ({baseCurrency})</p>
          <p className="right-header">Time</p>
        </div>
      </div>
      <div>
        {!!recentMarketTrades && (
          <table className="table">
            <tbody>
              {recentMarketTrades.map((trade, i) => {
                return (
                  <tr key={i}>
                    <td
                      className="left-text"
                      style={{
                        color: trade.side === 'buy' ? '#25FF3B' : '#EF4444',
                      }}
                    >
                      {market?.tickSize && !isNaN(trade.price)
                        ? Number(trade.price).toFixed(
                            getDecimalCount(market.tickSize),
                          )
                        : trade.price}
                    </td>
                    <td className="center-text">
                      {' '}
                      {market?.minOrderSize && !isNaN(trade.size)
                        ? Number(trade?.size).toFixed(
                            getDecimalCount(market?.minOrderSize),
                          )
                        : trade.size}
                    </td>
                    <td className="right-text">
                      {dayjs(market?.time).format('HH:mm:ss')}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </Container>
  );
};

export default TradeTable;
