import { PublicKey } from '@solana/web3.js';
import Markets from './markets.json';

export const MARKETS: Array<{
  address: PublicKey;
  tokenMint: PublicKey;
  tokenName: string;
  name: string;
  programId: PublicKey;
  deprecated: boolean;
}> = []