import React, { useState } from 'react';
import DataTable from '../layout/DataTable';

import styled from 'styled-components';
import { Button, Col, Row, Tag } from 'antd';
import { cancelOrder } from '../../utils/send';
import { useWallet } from '../../utils/wallet';
import { useSendConnection } from '../../utils/connection';
import { notify } from '../../utils/notifications';
import { DeleteOutlined } from '@ant-design/icons';
import DeleteButton from '../../assets/delete-btn.svg';
import { OrderWithMarketAndMarketName } from '../../utils/types';
import { SearchOutlined } from '@ant-design/icons';

const CancelButton = styled(Button)`
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 9px 12px 9px 8px;
  gap: 5px;
  height: 22px;

  border: 2px solid #5f2b2b;
  border-radius: 6px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 30px;
  /* identical to box height, or 273% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* Alerts / Error / Dark */

  color: #ef4444;
  transition: all 0.5s;
  svg {
    transition: all 0.5s;
  }
  &:hover {
    background: #ef4444;
    color: #ffffff;
    border: 2px solid #ef4444;

    svg {
      fill: #ffffff;
    }
  }
`;

export default function OpenOrderTable({
  openOrders,
  onCancelSuccess,
  // pageSize,
  loading,
  marketFilter,
}: {
  openOrders: /* OrderWithMarketAndMarketName[] | null | undefined*/ any;
  onCancelSuccess?: () => void;
  pageSize?: number;
  loading?: boolean;
  marketFilter?: boolean;
}) {
  let { wallet } = useWallet();
  let connection = useSendConnection();

  console.log(openOrders, 'openOrders');

  const [cancelId, setCancelId] = useState(null);

  async function cancel(order) {
    //setCancelId(order?.orderId);
    try {
      if (!wallet) {
        return null;
      }

      await cancelOrder({
        order,
        market: order.market,
        connection,
        wallet,
      });
    } catch (e: any) {
      notify({
        message: 'Error cancelling order',
        description: e.message,
        type: 'error',
      });
      return;
    } finally {
      setCancelId(null);
    }
    onCancelSuccess && onCancelSuccess();
  }

  const marketFilters = [
    ...new Set((openOrders || []).map((orderInfos) => orderInfos.marketName)),
  ].map((marketName) => {
    return { text: marketName, value: marketName };
  });

  const columns = [
    {
      title: 'Market',
      dataIndex: 'marketName',
      key: 'marketName',
      filters: marketFilter ? marketFilters : undefined,
      onFilter: (value, record) => record.marketName.indexOf(value) === 0,
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      render: (side) => (
        <Tag
          color={
            side === 'buy' ? 'rgba(37, 255, 59, 0.3)' : 'rgba(239, 68, 68, 0.3'
          }
          style={{
            border: side === 'buy' ? '1px solid #25ff3b' : '1px solid #EF4444',
          }}
        >
          {side.charAt(0).toUpperCase() + side.slice(1)}
        </Tag>
      ),
      sorter: (a, b) => {
        if (a.side === b.side) {
          return 0;
        } else if (a.side === 'buy') {
          return 1;
        } else {
          return -1;
        }
      },
      showSorterTooltip: false,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => b.size - a.size,
      showSorterTooltip: false,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => b.price - a.price,
      showSorterTooltip: false,
    },
    {
      key: 'orderId',
      render: (order) => (
        <div style={{ textAlign: 'right' }}>
          <CancelButton
            onClick={() => cancel(order)}
            loading={cancelId + '' === order?.orderId + ''}
          >
            <svg
              width="11"
              height="10"
              viewBox="0 0 11 10"
              fill="#EF4444"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2.16666 3H1.61111V8.89999C1.61111 9.19172 1.72817 9.47151 1.93654 9.6778C2.14492 9.88409 2.42753 9.99998 2.72222 9.99998H8.27778C8.57246 9.99998 8.85507 9.88409 9.06345 9.6778C9.27182 9.47151 9.38889 9.19172 9.38889 8.89999V3H2.16666ZM4.38889 8.34999H3.27777V4.4001H4.38889V8.34999ZM7.72222 8.34999H6.61111V4.4001H7.72222V8.34999ZM8.0656 0.9L7.16671 0.000113726H3.83338L2.93449 0.9H0.500046V2H10.5V0.9H8.0656Z" />
            </svg>{' '}
            Cancel
          </CancelButton>
        </div>
      ),
    },
  ];
  const dataSource = (openOrders || []).map((order) => ({
    ...order,
    key: order.orderId,
    price: `${order.price.toFixed(3)}`,
    size: `${order.size.toFixed(3)}`,
  }));
 
  return (
    <Row>
      <Col span={24}>
        <DataTable
          emptyLabel="No open orders"
          dataSource={dataSource}
          columns={columns}
          // pagination={true}
          // pageSize={pageSize ? pageSize : 5}
          loading={loading !== undefined && loading}
        />
      </Col>
    </Row>
  );
}
