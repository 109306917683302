import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 12px 12px 25px 12px;
`;
export const ContentDetails = styled.div`
  &:not(:last-child) {
    margin-bottom: 25px;
  }
`;
export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
`;
export const Heading = styled.h1`
  /* app-heading/H3 */
  margin: 0;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  /* identical to box height, or 231% */

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* primary/semi green-blue */

  color: #01c0fc;

  /* Primary/Blue */

  text-shadow: 0px 3px 9px #0089d6;
`;
export const Hr = styled.div`
  width: 221px;
  height: 0px;

  /* backgrounds/B5 */

  border: 2px solid #28333f;
`;
export const Info = styled.img`
  width: 14px;
  height: 14px;
`;

export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

export const TextContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Text = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;

  /* identical to box height, or 178% */

  text-transform: uppercase;

  /* backgrounds/G2 */

  color: #4d647b;
  margin-bottom: 0;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  .ant-select {
    /* backgrounds/B3 */

    background: #1f2934;
    border-radius: 6px;
    border: 1px solid transparent;
    margin-right: 0 !important;

    .ant-select-arrow {
      .anticon-down {
        svg {
          fill: hsla(194, 99%, 50%, 1);
        }
      }
    }

    .ant-select-selector {
      border: none;

      .ant-select-selection-item {
        font-weight: 700;
      }
    }

    svg {
      fill: #4d647b;
      width: 13px;
      height: 13px;
    }
  }

  .ant-select-focused {
    border: 1px solid transparent !important;
    box-shadow: 0px 2px 10px -1px transparent;
  }
`;
export const CopyText = styled.img`
  margin-right: 9px;
`;

export const Description = styled.h3`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  /* or 231% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* White */

  color: #ffffff;
  margin-bottom: 0;
`;
export const BottomContent = styled.div`
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const Deposit = styled.button`
  padding: 9px 14px;
  gap: 10px;

  width: 131px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* buttons/blue */

  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* line-height: 13px; */
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }
`;
export const Settle = styled(Deposit)``;

export const AllWallLinK = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 163% */

  /* backgrounds/G2 */

  color: #4d647b;
  text-align: center;
`;
export const Span = styled.span`
  color: #01c0fc;
`;
