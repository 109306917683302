import styled from 'styled-components';

export const Container = styled.div`
  /* position: relative; */
  font-family: 'Space Grotesk';
  display: flex;
  flex-direction: column;

  height: calc(100% - 3px);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .ask-table{
    height:48%;
    position:relative;
    .ask-subtable{
      position:absolute;
      bottom:0px;
    }
  }
  .bid-table{
    height:48%;
  }
  .topbar {
    height: 62px;
    background: #1a232b;
    /* position: sticky; */
    /* top: 0; */
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
   
    .header {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: #ffffff;
      z-index: 10;
    }

    .tableHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-header,
      .right-header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 20px;
        /* identical to box height, or 195% */

        display: flex;
        align-items: center;
        text-align: right;
        text-transform: uppercase;

        /* backgrounds/G3 */

        color: #687d95;
      }
      .right-header {
        /* padding-right: 5px; */
      }
    }
  }

  &::-webkit-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .table {
    width: 100%;

    border-collapse: collapse;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    overflow: hidden;
    tr:nth-child(odd) {
      background: #161d23;
    }
    tr:nth-child(even) {
      background: #12181f;
    }
    tbody {
      border-radius: 10px;
    }
    td {
      height: 21px;
    }

    .left-text {
      text-align: left;
      color: #abbdd1;
      font-size: 11px;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      padding-left: 15px;
    }

    .right-text {
      text-align: right;

      font-size: 11px;

      color: #ef4444;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      padding-right: 15px;
    }
  }

  .dividerLine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    gap: 10px;
    width: 100%;
    height: 21px;

    /* backgrounds/B1 */

    background: #12181f;

    .leftLine {
      width: 91.5px;
      height: 0px;

      /* backgrounds/B5 */

      border: 2px solid #28333f;
    }
    .rightLine {
      width: 91.5px;
      height: 0px;

      /* backgrounds/B5 */

      border: 2px solid #28333f;
    }

    .arrow-button {
      

      svg {
    
      
      }
    }
  }

  .table-bottom {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    tr:nth-child(odd) {
      background: #12181f;
    }
    tr:nth-child(even) {
      background: #161d23;
    }

    .right-text {
      color: #25ff3b;
    }
  }
`;
export const Button = styled.button.attrs(
  (props: { symbol: String;}) =>
    props)`
    display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 6px;
      gap: 5px;

     // width: 55px;
      height: 24px;
      background:${(props) =>
        props.symbol === "down"
          ? "rgba(255, 14, 14, 0.29)": "rgba(37, 255, 59, 0.1)"};
      border-radius: 6px;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      /* identical to box height, or 250% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

      /* pool/funded */
 

      border: none;
      outline: none;
`