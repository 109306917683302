import React from 'react';
import { Row, Col, Tag } from 'antd';
import { useFills, useMarket } from '../../utils/markets';
import DataTable from '../layout/DataTable';

export default function FillsTable() {
  const fills = useFills();

  const { quoteCurrency } = useMarket();

  const columns = [
    {
      title: 'Market',
      dataIndex: 'marketName',
      key: 'marketName',
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      render: (side) => (
        <Tag
          color={
            side === 'buy' ? 'rgba(37, 255, 59, 0.3)' : 'rgba(239, 68, 68, 0.3'
          }
          style={{
            border: side === 'buy' ? '1px solid #25ff3b' : '1px solid #EF4444',
          }}
        >
          {side.charAt(0).toUpperCase() + side.slice(1)}
        </Tag>
      ),
    },
    {
      title: `Size`,
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: `Price`,
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: `Liquidity`,
      dataIndex: 'liquidity',
      key: 'liquidity',
    },
    {
      title: quoteCurrency ? `Fees (${quoteCurrency})` : 'Fees',
      dataIndex: 'feeCost',
      key: 'feeCost',
    },
  ];

  const dataSource = (fills || []).map((fill) => ({
    ...fill,
    key: `${fill.orderId}${fill.side}`,
    liquidity: fill.eventFlags.maker ? 'Maker' : 'Taker',
    feeCost: `${fill.feeCost.toFixed(6)}`,
    price: `${fill.price.toFixed(3)}`,
    size: `${fill.size.toFixed(3)}`,
    marketName: `${fill.marketName}`,
  }));

  console.log('dataSource', dataSource);
  return (
    <>
      <Row>
        <Col span={24}>
          <DataTable
            dataSource={dataSource}
            columns={columns}
            pagination={true}
            pageSize={5}
            emptyLabel="No fills"
          />
        </Col>
      </Row>
    </>
  );
}
