import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Popover, Row, Select, Typography } from 'antd';
import styled from 'styled-components';
// import Orderbook from '../components/Orderbook';
import UserInfoTable from '../components/UserInfoTable';
import Plus from '../assets/plus-line.svg';
import StandaloneBalancesDisplay from '../components/StandaloneBalancesDisplay';
import {
  getMarketInfos,
  getTradePageUrl,
  MarketProvider,
  useMarket,
  // useMarketsList,
  useUnmigratedDeprecatedMarkets,
} from '../utils/markets';
import TradeForm from '../components/TradeForm';
import TradesTable from '../components/TradesTable';
import LinkAddress from '../components/LinkAddress';
import DeprecatedMarketsInstructions from '../components/DeprecatedMarketsInstructions';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import CustomMarketDialog from '../components/CustomMarketDialog';
import { notify } from '../utils/notifications';
import { useHistory, useParams } from 'react-router-dom';
import { nanoid } from 'nanoid';
import CircleInfo from '../assets/circle-info.svg';
// import { TVChartContainer } from '../components/TradingView';
import { PublicKey } from '@solana/web3.js';
import { Card } from '../components/layout/Card';
import SbOneInfo from '../components/SbOneInfo/SbOneInfo';
import BuySell from '../components/BuySell/BuySell';
import BorrowerInfo from '../components/BorrowerInfo';
import TradeTable from '../components/TradeTable';
import TableView from '../components/TradingView/Table';
import OrderBookInfo from '../components/OrderBookInfo';
import Orderbook from '../components/Orderbook';
import axios from 'axios';
import { URL_MARKETS } from '../utils/config';
import {
  convertLastAddress,
  NETWORK_RPC,
  reformatPoolData,
  tokensData,
  USDC_MINT_ADDRESS,
} from '../utils/tools';
import { ENV, TokenInfo, TokenListProvider } from '@solana/spl-token-registry';
import { useConnection, useSendConnection } from '../utils/connection';
// Use following stub for quick setup without the TradingView private dependency
// function TVChartContainer() {
//   return <></>
// }

const { Option, OptGroup } = Select;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1403px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  .borderNone .ant-select-selector {
    border: none !important;
  }
  @media (max-width: 1530px) {
    max-width: 1228px;
  }
`;

const DivWrapper = styled.div`
  width: '100%';
  maxwidth: 1403px;
  margin: '0 auto';
  @media (max-width: 1530px) {
    max-width: 1228px;
  }
`;

export const SelectWrapper = styled.div`
  .ant-select {
    /* backgrounds/B3 */

    background: #1a232b;
    border-radius: 6px;
    border: 1px solid transparent;

    .ant-select-arrow {
      .anticon-down {
        svg {
          fill: hsla(194, 99%, 50%, 1);
        }
      }
    }

    .ant-select-selector {
      border: none;
    }

    svg {
      fill: #4d647b;
      width: 13px;
      height: 13px;
    }
  }

  .ant-select-focused {
    border: 1px solid rgba(1, 192, 252, 0.8) !important;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }
`;

export const PlusWrapper = styled.div`
  width: 14px;
  height: 14px;
  background: #01c0fc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 8px;
    height: 8px;
  }
`;

export default function TradePage() {
  const { marketAddress } = useParams();
  const [tokenMap, setTokenMap] = useState<Map<string, TokenInfo>>(new Map());
  const connection = useConnection();
  const [poolDataFormat, setPoolDataFormat] = useState({});
  // Get list of token map
  useEffect(() => {
    new TokenListProvider().resolve().then((tokens) => {
      const chainID =
        NETWORK_RPC === 'mainnet-beta'
          ? ENV.MainnetBeta
          : NETWORK_RPC === 'devnet'
          ? ENV.Devnet
          : ENV.Testnet;

      const tokenList = tokens.filterByChainId(chainID).getList();

      const tokenMap = tokenList.reduce((map, item) => {
        map.set(item.address, item);
        return map;
      }, new Map());

      tokensData.map((tokenMint) => {
        if (!tokenMap.get(tokenMint.address)) {
          tokenMap.set(tokenMint.address, {
            chainId: chainID,
            address: tokenMint.address,
            name: tokenMint.name,
            decimals: tokenMint.decimals,
            symbol: tokenMint.symbol,
            logoURI: undefined,
          });
        }
      });

      if (!tokenMap.get(USDC_MINT_ADDRESS)) {
        tokenMap.set(USDC_MINT_ADDRESS, {
          chainId: chainID,
          address: USDC_MINT_ADDRESS,
          name: 'SPL-USDC',
          decimals: 6,
          symbol: 'USDC',
          logoURI: undefined,
        });
      }
      setTokenMap(tokenMap);
    });
  }, [setTokenMap]);

  // console.log({marketAddress: marketAddress});
  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress));
      const fetchData = async () => {
        try {
          const resp = await axios.post(`${URL_MARKETS}/get-pool-info`, {
            marketAddress: marketAddress,
          });
          const poolInfo = resp.data.data.poolInfo;
          if (poolInfo) {
            //console.log({poolInfo:poolInfo});
            const dataFormatted = await reformatPoolData(
              connection,
              poolInfo,
              tokenMap,
            );
            setPoolDataFormat(dataFormatted);
            //console.log({dataFormatted: dataFormatted});
          }
        } catch (e) {
          console.log(e);
        }
      };
      fetchData().catch(console.error);
    }
  }, [marketAddress]);

  const history = useHistory();
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address));
  }

  return (
    <MarketProvider
      marketAddress={marketAddress}
      setMarketAddress={setMarketAddress}
    >
      <TradePageInner
        poolDataFormat={poolDataFormat}
        marketAddressInit={marketAddress}
      />
    </MarketProvider>
  );
}

function TradePageInner({ marketAddressInit, poolDataFormat }) {
  const {
    market,
    marketName,
    customMarkets,
    setCustomMarkets,
    setMarketAddress,
  } = useMarket();

  // const markets = useMarketsList();
  // console.log({marketsData: markets});

  const [markets, setMarkets] = useState<any>([]);

  // let markets:Array<any> = [];
  async function handleFetchData(): Promise<void> {
    // const response = await fetch(`https://api.superapy.finance/getAllMarkets`);
    const response = await axios.get(`${URL_MARKETS}/getAllMarkets`);
    if (response) {
      const marketsData = response.data;
      // console.log({marketsData: marketsData});
      if (marketsData) {
        marketsData.map((item: any) => {
          item.address = new PublicKey(item.address);
          item.programId = new PublicKey(item.programId);
        });
        if(!marketAddressInit) {
          setMarketAddress(marketsData[0].address)
        }
        setMarkets(marketsData);
      }
    }
  }

  useEffect(() => {
    handleFetchData().then((r) => console.log(r));
  }, []);

  const [handleDeprecated, setHandleDeprecated] = useState(false);
  const [addMarketVisible, setAddMarketVisible] = useState(false);
  const deprecatedMarkets = useUnmigratedDeprecatedMarkets();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    document.title = marketName ? `${marketName} — RaiseREX` : 'RaiseREX';
  }, [marketName]);

  const changeOrderRef =
    useRef<({ size, price }: { size?: number; price?: number }) => void>();

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const width = dimensions?.width;
  const componentProps = {
    onChangeOrderRef: (ref) => (changeOrderRef.current = ref),
    onPrice: useCallback(
      (price) => changeOrderRef.current && changeOrderRef.current({ price }),
      [],
    ),
    onSize: useCallback(
      (size) => changeOrderRef.current && changeOrderRef.current({ size }),
      [],
    ),
  };
  const component = (() => {
    if (handleDeprecated) {
      return (
        <DeprecatedMarketsPage
          switchToLiveMarkets={() => setHandleDeprecated(false)}
        />
      );
    } else if (width < 1000) {
      return <RenderSmaller {...componentProps} />;
    } else if (width < 1450) {
      return <RenderSmall {...componentProps} />;
    } else {
      return <RenderNormal {...componentProps} />;
    }
  })();

  const onAddCustomMarket = (customMarket) => {
    const marketInfo = getMarketInfos(customMarkets).some(
      (m) => m.address.toBase58() === customMarket.address,
    );
    if (marketInfo) {
      notify({
        message: `A market with the given ID already exists`,
        type: 'error',
      });
      return;
    }
    const newCustomMarkets = [...customMarkets, customMarket];
    setCustomMarkets(newCustomMarkets);
    setMarketAddress(customMarket.address);
  };

  const onDeleteCustomMarket = (address) => {
    const newCustomMarkets = customMarkets.filter((m) => m.address !== address);
    setCustomMarkets(newCustomMarkets);
  };

  console.log({ marketsData: markets });

  return (
    <>
      <CustomMarketDialog
        visible={addMarketVisible}
        onClose={() => setAddMarketVisible(false)}
        onAddCustomMarket={onAddCustomMarket}
      />

      <Wrapper>
        <Row
          align="middle"
          style={{ paddingLeft: 5, paddingRight: 5 }}
          gutter={1}
        >
          <Col>
            <MarketSelector
              markets={markets}
              setHandleDeprecated={setHandleDeprecated}
              placeholder={'Select market'}
              customMarkets={customMarkets}
              onDeleteCustomMarket={onDeleteCustomMarket}
              marketAddressInit={marketAddressInit}
            />
          </Col>
          {market ? (
            <Col style={{ marginLeft: '6px' }}>
              <Popover
                content={<LinkAddress address={market.publicKey.toBase58()} />}
                placement="bottomRight"
                title="Market address"
                trigger="click"
              >
                <InfoCircleOutlined style={{ color: '#2abdd2' }} />
              </Popover>
            </Col>
          ) : null}
          <Col>
            {/* <PlusCircleOutlined style={{ color: '#2abdd2' }} /> */}
            {/* <PlusWrapper onClick={() => setAddMarketVisible(true)}>
              <img src={Plus} alt="" />
            </PlusWrapper> */}
          </Col>
          <Col
            style={{
              padding: '0 0 0 4px',
            }}
          >
            {/* <Popover
              // content={endpoint}
              placement="bottomRight"
              title="URL"
              trigger="hover"
            >
             

              <img
                style={{
                  width: '17',
                  height: '16px',
                  marginBottom: '2.688px',
                }}
                src={CircleInfo}
                alt=""
              />
            </Popover> */}
          </Col>
          {deprecatedMarkets && deprecatedMarkets.length > 0 && (
            <React.Fragment>
              <Col>
                <Typography>
                  You have unsettled funds on old markets! Please go through
                  them to claim your funds.
                </Typography>
              </Col>
              <Col>
                <Button onClick={() => setHandleDeprecated(!handleDeprecated)}>
                  {handleDeprecated ? 'View new markets' : 'Handle old markets'}
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
        {/* {component} */}
        <DivWrapper>
          <Row align="top" gutter={0}>
            <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
              <Row align="top" gutter={2}>
                <Col xs={24} sm={24} md={24} lg={16} xl={10} xxl={10}>
                  <Card
                    height="510px"
                    style={{
                      padding: 0,
                    }}
                  >
                    <BorrowerInfo poolDataFormat={poolDataFormat} />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={7} xxl={7}>
                  <Card padding={'2px 0px'} height="510px">
                    <TradeTable />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={7}>
                  <Card height="510px">
                    <OrderBookInfo {...componentProps} />
                  </Card>
                </Col>
              </Row>
              <Row align="top" gutter={1}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Card
                    height="315px"
                    style={{
                      padding: 0,
                    }}
                  >
                    <TableView />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={6}
              xxl={6}
              style={{ padding: '0' }}
            >
              <Row align="top" gutter={0}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Card
                    style={{
                      padding: 0,
                    }}
                    height="338px"
                  >
                    <BuySell
                      setChangeOrderRef={componentProps.onChangeOrderRef}
                    />
                  </Card>
                </Col>
              </Row>
              <Row align="top" gutter={2}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Card
                    height="487px"
                    style={{
                      padding: 0,
                    }}
                  >
                    {' '}
                    <SbOneInfo />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </DivWrapper>
      </Wrapper>
    </>
  );
}

function MarketSelector({
  markets,
  placeholder,
  setHandleDeprecated,
  customMarkets,
  onDeleteCustomMarket,
  marketAddressInit,
}) {
  const { market, setMarketAddress } = useMarket();

  const onSetMarketAddress = (marketAddress) => {
    setHandleDeprecated(false);
    console.log({
      dataMarket: {
        marketAddressInit: marketAddressInit,
        marketAddress: marketAddress,
      },
    });
    setMarketAddress(marketAddress);
  };

  const extractBase = (a) => a.split('/')[0];
  const extractQuote = (a) => a.split('/')[1];

  const selectedMarket = getMarketInfos(customMarkets)
    .find(
      (proposedMarket) =>
        market?.address && proposedMarket.address.equals(market.address),
    )
    ?.address?.toBase58();
  console.log({ market: market });

  return (
    <SelectWrapper>
      <Select
        showSearch
        size={'large'}
        style={{
          width: 200,
          fontSize: '12px',
          fontWeight: '700',
          textTransform: 'uppercase',
        }}
        placeholder={placeholder || 'Select a market'}
        optionFilterProp="name"
        onSelect={onSetMarketAddress}
        listHeight={400}
        value={marketAddressInit ? marketAddressInit : selectedMarket}
        filterOption={(input, option) => {
          const shortAddress = convertLastAddress(input);
          return (
            option?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option?.name?.toLowerCase().indexOf(shortAddress.toLowerCase()) >= 0
          );
        }}
      >
        {customMarkets && customMarkets.length > 0 && (
          <OptGroup label="Custom">
            {customMarkets.map(({ address, name }, i) => (
              <Option
                value={address}
                key={nanoid()}
                name={name}
                style={{
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  padding: '10px 16px',
                  // backgroundColor: i % 2 === 0 ? 'rgb(39, 44, 61)' : null,
                }}
              >
                <Row>
                  <Col flex="auto">{name}</Col>
                  {selectedMarket !== address && (
                    <Col>
                      <DeleteOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          onDeleteCustomMarket && onDeleteCustomMarket(address);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Option>
            ))}
          </OptGroup>
        )}
        <OptGroup
          label="Markets"
          style={{
            paddingLeft: '14px',
          }}
        >
          {markets
            .sort((a, b) =>
              extractQuote(a.name) === 'USDT' && extractQuote(b.name) !== 'USDT'
                ? -1
                : extractQuote(a.name) !== 'USDT' &&
                  extractQuote(b.name) === 'USDT'
                ? 1
                : 0,
            )
            .sort((a, b) =>
              extractBase(a.name) < extractBase(b.name)
                ? -1
                : extractBase(a.name) > extractBase(b.name)
                ? 1
                : 0,
            )
            .map(({ address, name, deprecated }, i) => (
              <Option
                value={address.toBase58()}
                key={nanoid()}
                name={name}
                style={{
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  padding: '14px 18px',
                  // @ts-ignore
                  // backgroundColor: i % 2 === 0 ? 'rgb(39, 44, 61)' : null,
                }}
              >
                {name} {deprecated ? ' (Deprecated)' : null}
              </Option>
            ))}
        </OptGroup>
      </Select>
    </SelectWrapper>
  );
}

const DeprecatedMarketsPage = ({ switchToLiveMarkets }) => {
  return (
    <>
      <Row>
        <Col flex="auto">
          <DeprecatedMarketsInstructions
            switchToLiveMarkets={switchToLiveMarkets}
          />
        </Col>
      </Row>
    </>
  );
};

const RenderNormal = ({ onChangeOrderRef, onPrice, onSize }) => {
  return (
    <Row
      style={{
        minHeight: '900px',
        flexWrap: 'nowrap',
      }}
    >
      <Col flex="auto" style={{ height: '50vh' }}>
        {/*<Row style={{ height: '100%' }}>*/}
        {/*  <TVChartContainer />*/}
        {/*</Row>*/}
        <Row style={{ height: '70%' }}>
          <UserInfoTable />
        </Row>
      </Col>
      <Col flex={'360px'} style={{ height: '100%' }}>
        <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        {/*<TradesTable smallScreen={false} />*/}
      </Col>
      <Col
        flex="400px"
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TradeForm setChangeOrderRef={onChangeOrderRef} />
        <StandaloneBalancesDisplay />
      </Col>
    </Row>
  );
};

const RenderSmall = ({ onChangeOrderRef, onPrice, onSize }) => {
  return (
    <>
      {/*<Row style={{ height: '30vh' }}>*/}
      {/*  <TVChartContainer />*/}
      {/*</Row>*/}
      <Row
        style={{
          height: '900px',
        }}
      >
        <Col flex="auto" style={{ height: '100%', display: 'flex' }}>
          <Orderbook
            smallScreen={true}
            depth={13}
            onPrice={onPrice}
            onSize={onSize}
          />
        </Col>
        <Col flex="auto" style={{ height: '100%', display: 'flex' }}>
          {/*<TradesTable smallScreen={true} />*/}
        </Col>
        <Col
          flex="400px"
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <TradeForm setChangeOrderRef={onChangeOrderRef} />
          <StandaloneBalancesDisplay />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <UserInfoTable />
        </Col>
      </Row>
    </>
  );
};

const RenderSmaller = ({ onChangeOrderRef, onPrice, onSize }) => {
  return (
    <>
      {/*<Row style={{ height: '50vh' }}>*/}
      {/*  <TVChartContainer />*/}
      {/*</Row>*/}
      <Row>
        <Col xs={24} sm={12} style={{ height: '100%', display: 'flex' }}>
          <TradeForm style={{ flex: 1 }} setChangeOrderRef={onChangeOrderRef} />
        </Col>
        <Col xs={24} sm={12}>
          <StandaloneBalancesDisplay />
        </Col>
      </Row>
      <Row
        style={{
          height: '500px',
        }}
      >
        <Col xs={24} sm={12} style={{ height: '100%', display: 'flex' }}>
          <Orderbook smallScreen={true} onPrice={onPrice} onSize={onSize} />
        </Col>
        <Col xs={24} sm={12} style={{ height: '100%', display: 'flex' }}>
          {/*<TradesTable smallScreen={true} />*/}
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <UserInfoTable />
        </Col>
      </Row>
    </>
  );
};
