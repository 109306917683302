import styled from 'styled-components';

export const Card = styled.div`
  margin: 2px;
  padding: ${({ padding }) => padding || '2px 5px'};
  overflow: hidden;
  background-color: #1a232b;
  border-radius: 8px;
  height: ${({ height }) => height || 'auto'};
`;
