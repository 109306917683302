import React, { Fragment } from 'react';
import { H4, WrapperContent } from './style';
import { BsInfoCircle } from 'react-icons/bs';
import { Popover } from 'antd';

export function CreditInfoModal({ pool }) {
  const content = (
    <div className="flex flex-col gap-1">
      {pool?.cpUsername ? (
        <div className="text-[#687D95] text-xs font-medium text-subpart2">
          Username: {pool?.cpUsername}
        </div>
      ) : (
        ''
      )}
      {pool?.creditScore ? (
        <div className="text-[#687D95] text-xs font-medium">
          Credit Score: {pool?.creditScore}
        </div>
      ) : (
        ''
      )}
      {pool?.creditScoreRating ? (
        <div className="text-[#687D95] text-xs font-medium">
          Credit Score Rating: {pool?.creditScoreRating}
        </div>
      ) : (
        ''
      )}
      {pool?.borrowCapacity ? (
        <div className="text-[#687D95] text-xs font-medium">
          Borrow Capacity: {pool?.borrowCapacity}
        </div>
      ) : (
        ''
      )}
    </div>
  );
  return (
    <>
      <Popover
        className="popover-content"
        placement="topLeft"
        content={content}
        trigger="click"
      >
        <WrapperContent>
          <text className="header-subpart2">CREDORA VERIFIED</text>
          <BsInfoCircle className="ml-1 ml-2 cursor-pointer text-xs text-[#01C0FC]" />
        </WrapperContent>
      </Popover>
    </>
  );
}
