import React from 'react';
import { Dropdown, Menu } from 'antd';
import { useWallet } from '../utils/wallet';
import LinkAddress from './LinkAddress';
import styled from 'styled-components';
import ConnectLogo from '../assets/connect.png';

export const ButtonWrapper = styled.div.attrs(
  (props: { connected: String }) => props,
)`
  .ant-btn-group > .ant-btn:first-child:not(:last-child) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .ant-btn-group > .ant-btn:last-child:not(:first-child) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .ant-btn:first-child {
    background: ${({ connected }) =>
      connected
        ? `linear-gradient(180.32deg, rgba(34, 151, 241, 0.18) 0.31%, rgba(1, 252, 252, 0.18) 125.68%, rgba(22, 29, 35, 0) 184.05%), #161D23`
        : '#01c0fc'};

    border: none;

    display: flex;
    align-items: center;

    > span {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      /* or 250% */

      text-align: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: ${({ connected }) => (connected ? '#ffffff' : '#12181f')};
    }
  }
  .ant-btn:last-child {
    background: #1a232b;
    border: none;

    svg {
      fill: #4d647b;
    }
  }
`;

export default function WalletConnect() {
  const { connected, wallet, select, connect, disconnect } = useWallet();
  const publicKey = (connected && wallet?.publicKey?.toBase58()) || '';
  
  const menu = (
    <Menu>
      {connected && <LinkAddress shorten={true} address={publicKey} />}
      <Menu.Item key="3" onClick={select}>
        Change Wallet
      </Menu.Item>
    </Menu>
  );

  return (
    <ButtonWrapper connected={connected}>
      <Dropdown.Button
        onClick={connected ? disconnect : connect}
        overlay={menu}
      >
        {connected && (
          <img
            style={{
              marginRight: '5px',
            }}
            src={ConnectLogo}
            alt=""
          />
        )}
        {connected ? 'disconnect' : 'Connect'}
      </Dropdown.Button>
    </ButtonWrapper>
  );
}
