import {
  // InfoCircleOutlined,
  // PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Col, Menu, Popover, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import TextLogo from '../assets/main-text-logo.svg';
import CircleInfo from '../assets/circle-info.svg';
import Plus from '../assets/plus-line.svg';
import styled from 'styled-components';
import { useWallet } from '../utils/wallet';
import { ENDPOINTS, useConnectionConfig } from '../utils/connection';
import Settings from './Settings';
import CustomClusterEndpointDialog from './CustomClusterEndpointDialog';
import { EndpointInfo } from '../utils/types';
import { notify } from '../utils/notifications';
import { Connection } from '@solana/web3.js';
import WalletConnect from './WalletConnect';
import AppSearch from './AppSearch';
import { getTradePageUrl } from '../utils/markets';

const Wrapper = styled.div`
  background-color: hsla(213, 27%, 9%, 1);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 30px;
  flex-wrap: wrap;

  .ant-select {
    /* backgrounds/B3 */
    border: 1px solid transparent;
    background: #1a232b;
    border-radius: 6px;

    .ant-select-arrow {
      .anticon-down {
        svg {
          fill: hsla(194, 99%, 50%, 1);
        }
      }
    }

    .ant-select-selector {
      border: none;
    }

    svg {
      fill: #4d647b;
      width: 13px;
      height: 13px;
    }
  }

  .ant-select-focused:nth-child(1) {
    border: 1px solid rgba(1, 192, 252, 0.8);
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }
  .ant-menu-item {
    a {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      /* identical to box height, or 250% */

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

      /* backgrounds/G2 */

      color: #4d647b;
      &:hover {
        color: #ffffff;
      }
    }

    span {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;

      /* identical to box height, or 250% */

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

      /* backgrounds/G2 */

      color: #4d647b;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .ant-menu-item-selected {
    span {
      color: #ffffff;
    }
  }

  .ant-menu-horizontal > .ant-menu-item::after {
    right: 0px;
    bottom: 15px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 0 0 20px;
  }

  .ant-select-selection-item {
    font-weight: 700;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #2abdd2;
  font-weight: bold;
  cursor: pointer;
  .logo {
    width: 21.91px;
    height: 15.96px;
    margin-right: 8px;
  }
  .textLogo {
  }
`;

export const PlusWrapper = styled.div`
  width: 16px;
  height: 16px;
  background: #01c0fc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 8px;
    height: 8px;
  }
`;

const EXTERNAL_LINKS = {
  '/learn':
    'https://docs.projectserum.com/trade-on-serum-dex/trade-on-serum-dex-1',
  '/add-market': 'https://serum-academy.com/en/add-market/',
  '/wallet-support': 'https://serum-academy.com/en/wallet-support',
  '/dex-list': 'https://serum-academy.com/en/dex-list/',
  '/developer-resources': 'https://serum-academy.com/en/developer-resources/',
  '/explorer': 'https://solscan.io',
  '/srm-faq': 'https://projectserum.com/srm-faq',
  '/swap': 'https://swap.projectserum.com',
};

export default function TopBar() {
  const { connected, wallet } = useWallet();
  const {
    endpoint,
    endpointInfo,
    setEndpoint,
    availableEndpoints,
    setCustomEndpoints,
  } = useConnectionConfig();
  const [addEndpointVisible, setAddEndpointVisible] = useState(false);
  const [testingConnection, setTestingConnection] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [searchFocussed, setSearchFocussed] = useState(false);

  const handleClick = useCallback(
    (e) => {
      if (!(e.key in EXTERNAL_LINKS)) {
        history.push(e.key);
      }
    },
    [history],
  );

  const onAddCustomEndpoint = (info: EndpointInfo) => {
    const existingEndpoint = availableEndpoints.some(
      (e) => e.endpoint === info.endpoint,
    );
    if (existingEndpoint) {
      notify({
        message: `An endpoint with the given url already exists`,
        type: 'error',
      });
      return;
    }

    const handleError = (e) => {
      console.log(`Connection to ${info.endpoint} failed: ${e}`);
      notify({
        message: `Failed to connect to ${info.endpoint}`,
        type: 'error',
      });
    };

    try {
      const connection = new Connection(info.endpoint, 'recent');
      connection
        .getBlockTime(0)
        .then(() => {
          setTestingConnection(true);
          console.log(`testing connection to ${info.endpoint}`);
          const newCustomEndpoints = [
            ...availableEndpoints.filter((e) => e.custom),
            info,
          ];
          setEndpoint(info.endpoint);
          setCustomEndpoints(newCustomEndpoints);
        })
        .catch(handleError);
    } catch (e) {
      handleError(e);
    } finally {
      setTestingConnection(false);
    }
  };

  const endpointInfoCustom = endpointInfo && endpointInfo.custom;
  useEffect(() => {
    const handler = () => {
      if (endpointInfoCustom) {
        setEndpoint(ENDPOINTS[0].endpoint);
      }
    };
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [endpointInfoCustom, setEndpoint]);

  const tradePageUrl = location.pathname.startsWith('/market-new/')
    ? location.pathname
    : getTradePageUrl();

  return (
    <>
      <CustomClusterEndpointDialog
        visible={addEndpointVisible}
        testingConnection={testingConnection}
        onAddCustomEndpoint={onAddCustomEndpoint}
        onClose={() => setAddEndpointVisible(false)}
      />
      <Wrapper>
        <LogoWrapper onClick={() => history.push(tradePageUrl)}>
          <div>
            <img className="textLogo" src={"/images/rzr-icon.svg"} width={'80px'} alt="" />
          </div>
        </LogoWrapper>
        <Menu
          mode="horizontal"
          onClick={handleClick}
          selectedKeys={[location.pathname]}
          style={{
            borderBottom: 'none',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'flex-end',
            flex: 1,
          }}
        >
          <Menu.Item className="menuItem" key={tradePageUrl}>
            TRADE
          </Menu.Item>
          {/*{!searchFocussed && (*/}
          {/*  <Menu.Item key="/swap" style={{ margin: '0 10px' }}>*/}
          {/*    <a*/}
          {/*      href={EXTERNAL_LINKS['/swap']}*/}
          {/*      target="_blank"*/}
          {/*      rel="noopener noreferrer"*/}
          {/*    >*/}
          {/*      SWAP*/}
          {/*    </a>*/}
          {/*  </Menu.Item>*/}
          {/*)}*/}
          {/* {connected && (!searchFocussed || location.pathname === '/balances') && (
            <Menu.Item key="/balances" style={{ margin: '0 10px' }}>
              BALANCES
            </Menu.Item>
          )}
          {connected && (!searchFocussed || location.pathname === '/orders') && (
            <Menu.Item key="/orders" style={{ margin: '0 10px' }}>
              ORDERS
            </Menu.Item>
          )} */}
          {/*{connected && (!searchFocussed || location.pathname === '/convert') && (*/}
          {/*  <Menu.Item key="/convert" style={{ margin: '0 10px' }}>*/}
          {/*    CONVERT*/}
          {/*  </Menu.Item>*/}
          {/*)}*/}
          {/*{(!searchFocussed || location.pathname === '/list-new-market') && (*/}
          {/*  <Menu.Item key="/list-new-market" style={{ margin: '0 10px' }}>*/}
          {/*    ADD MARKET*/}
          {/*  </Menu.Item>*/}
          {/*)}*/}
          {/*{!searchFocussed && (*/}
          {/*  <Menu.SubMenu*/}
          {/*    title="LEARN"*/}
          {/*    onTitleClick={() =>*/}
          {/*      window.open(EXTERNAL_LINKS['/learn'], '_blank')*/}
          {/*    }*/}
          {/*    style={{ margin: '0 0px 0 10px' }}*/}
          {/*  >*/}
          {/*    <Menu.Item key="/add-market">*/}
          {/*      <a*/}
          {/*        href={EXTERNAL_LINKS['/add-market']}*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener noreferrer"*/}
          {/*      >*/}
          {/*        Adding a market*/}
          {/*      </a>*/}
          {/*    </Menu.Item>*/}
          {/*    <Menu.Item key="/wallet-support">*/}
          {/*      <a*/}
          {/*        href={EXTERNAL_LINKS['/wallet-support']}*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener noreferrer"*/}
          {/*      >*/}
          {/*        Supported wallets*/}
          {/*      </a>*/}
          {/*    </Menu.Item>*/}
          {/*    <Menu.Item key="/dex-list">*/}
          {/*      <a*/}
          {/*        href={EXTERNAL_LINKS['/dex-list']}*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener noreferrer"*/}
          {/*      >*/}
          {/*        DEX list*/}
          {/*      </a>*/}
          {/*    </Menu.Item>*/}
          {/*    <Menu.Item key="/developer-resources">*/}
          {/*      <a*/}
          {/*        href={EXTERNAL_LINKS['/developer-resources']}*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener noreferrer"*/}
          {/*      >*/}
          {/*        Developer resources*/}
          {/*      </a>*/}
          {/*    </Menu.Item>*/}
          {/*    <Menu.Item key="/explorer">*/}
          {/*      <a*/}
          {/*        href={EXTERNAL_LINKS['/explorer']}*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener noreferrer"*/}
          {/*      >*/}
          {/*        Solana block explorer*/}
          {/*      </a>*/}
          {/*    </Menu.Item>*/}
          {/*    <Menu.Item key="/srm-faq">*/}
          {/*      <a*/}
          {/*        href={EXTERNAL_LINKS['/srm-faq']}*/}
          {/*        target="_blank"*/}
          {/*        rel="noopener noreferrer"*/}
          {/*      >*/}
          {/*        SRM FAQ*/}
          {/*      </a>*/}
          {/*    </Menu.Item>*/}
          {/*  </Menu.SubMenu>*/}
          {/*)}*/}
        </Menu>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingRight: 5,
          }}
        >
          <AppSearch
            onFocus={() => setSearchFocussed(true)}
            onBlur={() => setSearchFocussed(false)}
            focussed={searchFocussed}
            width={searchFocussed ? '350px' : '35px'}
            style={{
              background: 'red',
            }}
          />
        </div>
        <div>
          <Row
            align="middle"
            style={{ paddingLeft: 5, paddingRight: 5 }}
            gutter={16}
          >
            <Col
              style={{
                padding: '0 12px',
              }}
            >
              <PlusWrapper onClick={() => setAddEndpointVisible(true)}>
                <img src={Plus} alt="" />
              </PlusWrapper>
            </Col>
            <Col
              style={{
                padding: '0 4px 0 0',
              }}
            >
              <Popover
                content={endpoint}
                placement="bottomRight"
                title="URL"
                trigger="hover"
              >
                {/* <InfoCircleOutlined style={{ color: '#01C0FC' }} /> */}

                <img
                  style={{
                    paddingBottom: '3px',
                  }}
                  src={CircleInfo}
                  alt=""
                />
              </Popover>
            </Col>
            <Col>
              <Select
                onSelect={setEndpoint}
                value={endpoint}
                style={{
                  marginRight: 8,
                  width: '150px',
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                }}
              >
                {availableEndpoints.map(({ name, endpoint }) => (
                  <Select.Option
                    style={{
                      fontSize: '12px',
                      fontWeight: '700',
                      textTransform: 'uppercase',
                      padding: '10px 16px',
                    }}
                    value={endpoint}
                    key={endpoint}
                  >
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
        {connected && (
          <div>
            <Popover
              content={<Settings autoApprove={wallet?.autoApprove} />}
              placement="bottomRight"
              title="Settings"
              trigger="click"
            >
              <Button style={{ marginRight: 12, border: 'none', padding: 0 }}>
                <SettingOutlined
                  style={{
                    color: 'hsla(211, 23%, 39%, 1)',
                  }}
                />
              </Button>
            </Popover>
          </div>
        )}
        <div>
          <WalletConnect />
        </div>
      </Wrapper>
    </>
  );
}
