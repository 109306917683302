import React, { useRef, useEffect, useState } from 'react';
import { Container, Button } from './styles';
import { useMarket, useOrderbook, useMarkPrice } from '../../utils/markets';
import { isEqual, getDecimalCount } from '../../utils/utils';
import { useInterval } from '../../utils/useInterval';
import usePrevious from '../../utils/usePrevious';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const OrderBookInfo = ({ depth = 7, onPrice, onSize }) => {
  const markPrice = useMarkPrice();
  const [orderbook] = useOrderbook();
  const { baseCurrency, quoteCurrency } = useMarket();

  const currentOrderbookData = useRef(null);
  const lastOrderbookData = useRef(null);

  const [orderbookData, setOrderbookData] = useState(null);

  useInterval(() => {
    if (
      !currentOrderbookData.current ||
      JSON.stringify(currentOrderbookData.current) !==
        JSON.stringify(lastOrderbookData.current)
    ) {
      let bids = orderbook?.bids || [];
      let asks = orderbook?.asks || [];

      let sum = (total, [, size], index) =>
        index < depth ? total + size : total;
      let totalSize = bids.reduce(sum, 0) + asks.reduce(sum, 0);

      let bidsToDisplay = getCumulativeOrderbookSide(bids, totalSize, false);
      let asksToDisplay = getCumulativeOrderbookSide(asks, totalSize, true);

      currentOrderbookData.current = {
        bids: orderbook?.bids,
        asks: orderbook?.asks,
      };

      setOrderbookData({ bids: bidsToDisplay, asks: asksToDisplay });
    }
  }, 250);

  useEffect(() => {
    lastOrderbookData.current = {
      bids: orderbook?.bids,
      asks: orderbook?.asks,
    };
  }, [orderbook]);

  function getCumulativeOrderbookSide(orders, totalSize, backwards = false) {
    let cumulative = orders
      .slice(0, depth)
      .reduce((cumulative, [price, size], i) => {
        const cumulativeSize = (cumulative[i - 1]?.cumulativeSize || 0) + size;
        cumulative.push({
          price,
          size,
          cumulativeSize,
          sizePercent: Math.round((cumulativeSize / (totalSize || 1)) * 100),
        });
        return cumulative;
      }, []);
    if (backwards) {
      cumulative = cumulative.reverse();
    }
    return cumulative;
  }

  console.log('orderbookData', orderbookData?.bids);
  return (
    <Container>
      <div className="topbar" style={{ zIndex: '10' }}>
        <h4 className="header">orderbook</h4>
        <div className="tableHeading">
          <p className="left-header">Size ({baseCurrency})</p>

          <p className="right-header">Price ({quoteCurrency})</p>
        </div>
      </div>
      <div style={{ height: '100%', position: 'relative' }}>
        {/* ask table */}
        <div className="ask-table">
          <table className="table ask-subtable">
            <tbody>
              {orderbookData?.asks.map(({ price, size, sizePercent }) => (
                <OrderbookRow
                  key={price + ''}
                  price={price}
                  size={size}
                  side={'sell'}
                  sizePercent={sizePercent}
                  onPriceClick={() => onPrice(price)}
                  onSizeClick={() => onSize(size)}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="dividerLine">
          <div className="leftLine" />
          <MarkPriceComponent markPrice={markPrice} />
          <div className="rightLine" />
        </div>
        <div className="bid-table">
          <table className="table">
            <tbody>
              {orderbookData?.bids.map(({ price, size, sizePercent }) => (
                <OrderbookRow
                  key={price + ''}
                  price={price}
                  size={size}
                  side={'buy'}
                  sizePercent={sizePercent}
                  onPriceClick={() => onPrice(price)}
                  onSizeClick={() => onSize(size)}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

const OrderbookRow = React.memo(
  ({ side, price, size, sizePercent, onSizeClick, onPriceClick }) => {
    console.log();
    const element = useRef();

    const { market } = useMarket();

    let formattedSize =
      market?.minOrderSize && !isNaN(size)
        ? Number(size).toFixed(getDecimalCount(market.minOrderSize) + 1)
        : size;

    let formattedPrice =
      market?.tickSize && !isNaN(price)
        ? Number(price).toFixed(getDecimalCount(market.tickSize) + 1)
        : price;

    return (
      <tr>
        <td
          onClick={onSizeClick}
          className="left-text"
          style={{ color: '#ABBDD1', cursor: 'pointer' }}
        >
          {formattedSize}
        </td>
        <td
          onClick={onPriceClick}
          className="right-text"
          style={{
            color: side === 'buy' ? '#25FF3B' : '#EF4444',
            cursor: 'pointer',
          }}
        >
          {formattedPrice}
        </td>
      </tr>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps, nextProps, ['price', 'size', 'sizePercent']),
);

const MarkPriceComponent = React.memo(
  ({ markPrice }) => {
    const { market } = useMarket();
    const previousMarkPrice = usePrevious(markPrice);

    let markPriceColor =
      markPrice > previousMarkPrice
        ? '#25FF3B'
        : markPrice < previousMarkPrice
        ? '#EF4444'
        : '#25FF3B';

    const symbol =
      markPrice > previousMarkPrice
        ? 'up'
        : markPrice < previousMarkPrice
        ? 'down'
        : 'up';

    let formattedMarkPrice =
      markPrice &&
      market?.tickSize &&
      markPrice.toFixed(getDecimalCount(market.tickSize));

    return (
      <Button className="arrow-button" symbol={symbol}>
        {/* <svg
              fill="red"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <polygon points="7 7 15.586 7 5.293 17.293 6.707 18.707 17 8.414 17 17 19 17 19 5 7 5 7 7" />
            </svg> */}
        {/* {markPrice > previousMarkPrice && <FaArrowUp style={{fontSize:"0.8rem",color:'red'}}   /> }
        {markPrice < previousMarkPrice && <FaArrowDown style={{fontSize:"0.8rem",color:'red'}}  /> } */}
        <span style={{ color: markPriceColor }}>
          {formattedMarkPrice || '---'}
        </span>
      </Button>

      // <MarkPriceTitle justify="center">
      //   <Col style={{ color: markPriceColor }}>
      //     {markPrice > previousMarkPrice && (
      //       <ArrowUpOutlined style={{ marginRight: 5 }} />
      //     )}
      //     {markPrice < previousMarkPrice && (
      //       <ArrowDownOutlined style={{ marginRight: 5 }} />
      //     )}
      //     {formattedMarkPrice || '----'}
      //   </Col>
      // </MarkPriceTitle>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps, ['markPrice']),
);

export default OrderBookInfo;
