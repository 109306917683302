import { SbMetalendTrade } from './types';
import {Market} from "@charles-seteda/sb-js";
import {PublicKey} from "@solana/web3.js";
import {useConnection} from "./connection";
import axios from "axios";
import {URL_MARKETS} from "./config";

export default class SbMetalendApi {
  static URL: string = 'https://serum-api.bonfida.com/';

  static async get(path: string) {
    try {
      const response = await fetch(this.URL + path);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.success ? responseJson.data : null;
      }
    } catch (err) {
      console.log(`Error fetching from Bonfida API ${path}: ${err}`);
    }
    return null;
  }

  

  static async getRecentTrades(
    marketAddress: string,
  ): Promise<SbMetalendTrade[] | null> {

    // const recentTrades = [
    //   {
    //     market: "38Wy79qw41TqRTJCcSKpsmiNB5y6FG3iWrgVRtvELgcr",
    //     size: 1,
    //     price: 9,
    //     orderId: "asdassd",
    //     time: 457104960,
    //     side: 'buy',
    //     feeCost: 0,
    //     marketAddress: '38Wy79qw41TqRTJCcSKpsmiNB5y6FG3iWrgVRtvELgcr'
    //   },
    //   {
    //     market: "38Wy79qw41TqRTJCcSKpsmiNB5y6FG3iWrgVRtvELgcr",
    //     size: 23,
    //     price: 8,
    //     orderId: "asdassd",
    //     time: 457104960,
    //     side: 'sell',
    //     feeCost: 0,
    //     marketAddress: '38Wy79qw41TqRTJCcSKpsmiNB5y6FG3iWrgVRtvELgcr'
    //   },
    //   {
    //     market: "38Wy79qw41TqRTJCcSKpsmiNB5y6FG3iWrgVRtvELgcr",
    //     size: 54,
    //     price: 7,
    //     orderId: "asdassd",
    //     time: 457104960,
    //     side: 'buy',
    //     feeCost: 0,
    //     marketAddress: '38Wy79qw41TqRTJCcSKpsmiNB5y6FG3iWrgVRtvELgcr'
    //   },
    // ];
    // return recentTrades;

    let ret:any[] = [];
    try {
      const resp = await axios.post(`${URL_MARKETS}/recent-market-trade`, {
        marketAddress: marketAddress
      });
      const allData = resp.data.recentMarketTrade;
      if (allData) {
        allData.map((item) => {
          const data = item.data;
          if (data) {
            data.map((subItem:any) => {
              ret.push(subItem);
              return subItem;
            });
          }
          return item;
        });
      }
      // console.log({recentMarketTrade: ret});
      ret.sort((a, b) => {
        if (a.orderId > b.orderId) return 1;
        if (a.orderId < b.orderId) return -1;
        return 0;
      });
      return ret;
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
    return ret;

    // return SbMetalendApi.get(`trades/address/${marketAddress}`);
  }


  static async getBorrowerPoolInfo(
    marketAddress: string,
  ): Promise<SbMetalendTrade[] | null> {

    let ret:any[] = [];
    try {
      const resp = await axios.post(`${URL_MARKETS}/get-pool-info`, {
        marketAddress: marketAddress
      });
      const allData = resp.data;
     
       return allData.data
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
    return ret;

    // return SbMetalendApi.get(`trades/address/${marketAddress}`);
  }

}

export const BONFIDA_DATA_FEED = 'https://serum-api.bonfida.com/tv';
// export const BONFIDA_DATA_FEED = 'https://ssc-dao.genesysgo.net';
