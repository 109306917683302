import { Button } from 'antd';
import React from 'react';
import {
  useTokenAccounts,
  getSelectedTokenAccountForMint,
} from '../../utils/markets';
import DataTable from '../layout/DataTable';
import { useSendConnection } from '../../utils/connection';
import { useWallet } from '../../utils/wallet';
import { settleFunds } from '../../utils/send';
import { notify } from '../../utils/notifications';
import { useReferrer } from '../../utils/referrer';
import styled from 'styled-components';

const CancelButton = styled.button`
  padding: 9px 14px;
  gap: 10px;

  width: 99px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* buttons/blue */

  background: rgba(1, 192, 252, 0.3);
  border-radius: 6px;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* line-height: 13px; */
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: #ffffff;
  cursor: pointer;
  border: 2px solid rgba(0, 133, 255, 1);
  transition: all 0.3s ease-in;

  &:hover {
    background: transparent;
    box-shadow: 0px 2px 10px -1px rgba(1, 192, 252, 0.5);
  }
`;

export default function BalancesTable({
  balances,
  showMarket,
  hideWalletBalance,
  onSettleSuccess,
}) {
  const [accounts] = useTokenAccounts();
  const connection = useSendConnection();
  const { wallet } = useWallet();
  const { usdcRef, usdtRef } = useReferrer();

  async function onSettleFunds(market, openOrders) {
    try {
      await settleFunds({
        market,
        openOrders,
        connection,
        wallet,
        baseCurrencyAccount: getSelectedTokenAccountForMint(
          accounts,
          market?.baseMintAddress,
        ),
        quoteCurrencyAccount: getSelectedTokenAccountForMint(
          accounts,
          market?.quoteMintAddress,
        ),
        usdcRef,
        usdtRef,
      });
    } catch (e) {
      notify({
        message: 'Error settling funds',
        description: e.message,
        type: 'error',
      });
      return;
    }
    onSettleSuccess && onSettleSuccess();
  }

  const columns = [
    showMarket
      ? {
          title: 'Market',
          dataIndex: 'marketName',
          key: 'marketName',
        }
      : null,
    {
      title: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
    },
    hideWalletBalance
      ? null
      : {
          title: 'Wallet Balance',
          dataIndex: 'wallet',
          key: 'wallet',
        },
    {
      title: 'Orders',
      dataIndex: 'orders',
      key: 'orders',
    },
    {
      title: 'Unsettled',
      dataIndex: 'unsettled',
      key: 'unsettled',
    },
    {
      key: 'action',
      render: ({ market, openOrders, marketName }) => (
        <div style={{ textAlign: 'right' }}>
          <CancelButton onClick={() => onSettleFunds(market, openOrders)}>
            Settle {marketName}
          </CancelButton>
        </div>
      ),
    },
  ].filter((x) => x);

  return (
    <DataTable
      emptyLabel="No balances"
      dataSource={balances}
      columns={columns}
      pagination={false}
    />
  );
}
