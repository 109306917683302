import {Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useMarket, useRecentMarketTrades, useSbMetalendTrades} from '../utils/markets';
import {getDecimalCount} from '../utils/utils';
import FloatingElement from './layout/FloatingElement';
import {SbMetalendTrade} from '../utils/types';
import {useConnection} from "../utils/connection";
import {getCurrentRecentTrade} from "../utils/socketApi";

const Title = styled.div`
  color: rgba(255, 255, 255, 1);
`;
const SizeTitle = styled(Row)`
  padding: 20px 0 14px;
  color: #434a59;
`;

export default function PublicTrades({smallScreen}) {
    const {baseCurrency, quoteCurrency, market} = useMarket();
    const {recentMarketTrades, setRecentMarketTrades} = useRecentMarketTrades();
    const marketAddress = market?.address.toBase58();
    const connection = useConnection();
    useEffect(() => {
        if (marketAddress) {
            const fetchData = async () => {
                setRecentMarketTrades(await getCurrentRecentTrade(connection, marketAddress));
            }
            fetchData().catch(console.error);
        }
    }, [marketAddress]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (marketAddress) {
                const fetchData = async () => {
                    setRecentMarketTrades(await getCurrentRecentTrade(connection, marketAddress));
                }
                fetchData().catch(console.error);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [marketAddress]);

    return (
        <FloatingElement
            style={
                smallScreen
                    ? {flex: 1}
                    : {
                        marginTop: '10px',
                        minHeight: '270px',
                        maxHeight: 'calc(100vh - 700px)',
                    }
            }
        >
            <Title>Recent Market trades</Title>
            <SizeTitle>
                <Col span={8}>Price ({quoteCurrency}) </Col>
                <Col span={8} style={{textAlign: 'right'}}>
                    Size ({baseCurrency})
                </Col>
                {/*<Col span={8} style={{ textAlign: 'right' }}>*/}
                {/*  Time*/}
                {/*</Col>*/}
            </SizeTitle>
            {recentMarketTrades && (
                <div
                    style={{
                        marginRight: '-20px',
                        paddingRight: '5px',
                        overflowY: 'scroll',
                        maxHeight: smallScreen
                            ? 'calc(100% - 75px)'
                            : 'calc(100vh - 600px)',
                    }}
                >
                    {recentMarketTrades.map((trade: SbMetalendTrade, i: number) => (
                        <Row key={i} style={{marginBottom: 4}}>
                            <Col
                                span={8}
                                style={{
                                    color: trade.side === 'buy' ? '#41C77A' : '#F23B69',
                                }}
                            >
                                {market?.tickSize && !isNaN(trade.price)
                                    ? Number(trade.price).toFixed(
                                        getDecimalCount(market.tickSize),
                                    )
                                    : trade.price}
                            </Col>
                            <Col span={8} style={{textAlign: 'right'}}>
                                {market?.minOrderSize && !isNaN(trade.size)
                                    ? Number(trade.size).toFixed(
                                        getDecimalCount(market.minOrderSize),
                                    )
                                    : trade.size}
                            </Col>
                            {/*<Col span={8} style={{ textAlign: 'right', color: '#434a59' }}>*/}
                            {/*  {trade.time && new Date(trade.time).toLocaleTimeString()}*/}
                            {/*</Col>*/}
                        </Row>
                    ))}
                </div>
            )}
        </FloatingElement>
    );
}
