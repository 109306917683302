import styled from 'styled-components';
import { Card } from '../layout/Card';

export const Wrapper = styled(Card)`
  /* padding: 0 10px 0px; */
  position: relative;
  /* overflow-y: scroll; */
  height: ${({ minheight }) => minheight};
  /* padding-bottom: 30px; */

  /* padding: 0 5px 2px 5px; */
  padding: 0;
  .info-icon {
    position: sticky;

    top: 0;
    z-index: 10;
    cursor: pointer;
    text-align: end;

    .info-wrapper {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
  table {
    /* overflow-y: scroll; */
    border-radius: 6px;
  }

  .ant-table-container table > thead > tr > th span {
  }
  .ant-tabs-top > .ant-tabs-nav {
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
    background: #1a232b;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px 0 0 50px;
  }
  .ant-tabs-tab {
    padding: 18px 0;
  }
  .ant-table-container table > thead > tr > th {
    padding: 0 16px;
    height: 40px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    /* identical to box height, or 178% */

    text-transform: uppercase;

    /* backgrounds/G3 */

    color: #687d95;

    span {
      /* app-body/caption small */

      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;

      /* identical to box height, or 178% */

      text-transform: uppercase;

      /* backgrounds/G3 */

      color: #687d95;
    }
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: 1px solid #28333f;
    margin: 0 16px;
  }

  .ant-tabs-top > .ant-tabs-nav {
    padding: 0 16px;
  }

  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    /* identical to box height, or 250% */

    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

    /* White */
    color: #4d647b;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:hover {
    color: #ffffff;
  }
  .ant-table-tbody > tr {
    border-radius: 20px;
  }

  .ant-table-thead > tr > th {
    background: #1a232b;
    color: #4d647b;
  }
  .ant-table-placeholder {
    background: #242e38;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #1b2128;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
  }
  .ant-table-column-sorters {
    justify-content: flex-start;
    gap: 2px;
  }
  .ant-table-column-title,
  .ant-table-cell {
    flex: 0;
    text-transform: uppercase;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-table-cell:nth-child(2n) {
  }
  .ant-table-cell {
  }

  .ant-table-row:nth-child(2n) td {
    background-color: #161d23;
  }
  .ant-table-row td {
    background-color: #12181f;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0;
    padding: 0 16px;

    height: 37px;

    @media (min-width: 1024px) {
      &:not(:last-child) {
        //padding-right: 70px;
      }
    }
    /* app-heading/H4 */

    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    /* identical to box height, or 250% */

    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

    .ant-tag {
      padding: 0px 8px;

      height: 22px;
      transition: all.4s;
      cursor: pointer;

      /* pool/funded */

      border-radius: 6px;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700 !important;
      font-size: 11px;

      /* identical to box height, or 273% */

      text-align: center;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

      /* White */

      color: #ffffff;

      &:hover {
        background: transparent !important;
      }
    }
  }
  .ant-table-row:hover td {
    background-color: #161d23;
  }

  .ant-table-pagination.ant-pagination {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;
