import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Space Grotesk';
  display: flex;
  flex-direction: column;

  padding: 0 12px 12px 12px;
  height: 510px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .header-section {
    height: 62px;
    background: #1a232b;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    padding: 16px 12px;
  }
  .header {
    font-family: 'Space Grotesk';
    font-style: normal;

    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: #ffffff;
  }
  &::-webkit-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
`;

export const VerifiedText = styled.text`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: rgba(142, 218, 135, 1);
`;
export const Score = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
  color: #abbdd1;
`;
export const H4 = styled.text`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #1184a6;

  @media (min-width: 600px) {
    font-size: 10px;
  }
`;
export const SecondPart = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 10px;
  background: linear-gradient(
      0deg,
      rgba(1, 252, 252, 0.55) -54.05%,
      rgba(31, 107, 165, 0.55) 100%
    ),
    #12181f;
  border-radius: 10px;
`;
export const SubPart2 = styled.div`
  display: flex;
  justify-content: start;
`;
export const SubPart1 = styled.div`
  display: flex;
  justify-content: space-between;
  .sub-part1 {
    display: flex;
    flex-direction: column;
    .collateral-amount {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      background: linear-gradient(90.09deg, #01fcfc 2.67%, #0085ff 104.69%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .header-subpart1 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .sub-part2 {
    display: flex;
    flex-direction: column;
    .header-subpart2 {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 30px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: #8eda87;
      margin-right: 3px;
    }
    .text-subpart2 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      text-align: right;
      text-transform: uppercase;
      color: #abbdd1;
      .credit-score-text {
        font-weight: 800;
        color: #ffffff;
      }
    }
  }
`;

export const MiddleBar = styled.span`
  position: absolute;
  background: rgba(61, 79, 97, 1);

  top: 0;
  right: -16px;
  opacity: 0.34;
  border: 2px solid #1184a6;
  height: ${(props) => (props.totalWords === 2 ? '50px' : '35px')};
`;

export const DetailLowePart2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 0px;
  //height:75px;
  // height: ${(props) => (props.totalWords === 2 ? '89px' : '75px')};
  background: ${(props) =>
    props.active ? 'rgba(16, 67, 83, 0.65)' : '#1F2934'};
  border-radius: 8px;
  margin-top: 15px;
  position: relative;
  .flex-grow {
    display: flex;
    flex: 1;
  }

  .side-label {
  }

  .div1,
  .div2 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 36px;
    width: 95%;
    margin-left: 10px;
    .section {
      position: relative;
      display: flex;
      // align-items: center;
      //text-align: center;
      flex-direction: column;
      gap: 3px;
    }
  }
  .div3 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 90px;
  }
  .btn {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    /* or 250% */
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  }
  .white {
    color: #ffffff;
  }
  .red {
    color: #e95231;
  }
  .label1 {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #1184a6;
  }
  .label2 {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  }
  .sub-label {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: white;
    align-self: center;
  }
  .status-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

    color: #25ff3b;
  }
`;

export const DetailLowePart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  gap: 16px;
  height: 75px;
  // height: ${(props) => (props.totalWords === 2 ? '89px' : '75px')};
  background: ${(props) =>
    props.active ? 'rgba(16, 67, 83, 0.65)' : '#1F2934'};
  border-radius: 8px;
  margin-top: 15px;
  position: relative;

  .side-label {
  }

  .div1,
  .div2 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 36px;
    width: 95%;
    margin-left: 10px;
    .section {
      position: relative;
      display: flex;
      // align-items: center;
      //text-align: center;
      flex-direction: column;
      gap: 3px;
    }
  }
  .div3 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 90px;
  }
  .btn {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 30px;
    /* or 250% */
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  }
  .white {
    color: #ffffff;
  }
  .red {
    color: #e95231;
  }
  .label1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #1184a6;
  }
  .label2 {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  }
  .sub-label {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
    color: white;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .status-text {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

    color: #25ff3b;
  }
`;

export const HorizentalBar = styled.hr`
  position: absolute;
  width: 3px;
  height: 58px;
  left: 0px;
  top: 6px;
  border: 0;
  box-shadow: 0px 1px 6px 2px rgba(44, 221, 62, 0.25);
  border-radius: 6px;
  background: ${(props) =>
    props.sidebarColor === 'Active Subscription'
      ? '#01fcfc'
      : props.sidebarColor === 'Funded'
      ? '#25FF3B'
      : props.sidebarColor === 'Pre-Subscription'
      ? '#FFD029'
      : props.sidebarColor === 'Defaulted' ||
        props.sidebarColor === 'Successful' ||
        props.sidebarColor === 'Pool Ended'
      ? '#B3C7D9'
      : '#01FCFC'};
`;

export const StatusText = styled.text`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: ${(props) =>
    props.titleColor === 'Active Subscription'
      ? '#01FCFC'
      : props.titleColor === 'Funded'
      ? '#25FF3B'
      : props.titleColor === 'Pre-Subscription'
      ? '#FFD029'
      : props.titleColor === 'Defaulted' ||
        props.titleColor === 'Successful' ||
        props.titleColor === 'Pool Ended'
      ? '#B3C7D9'
      : '#01FCFC'};
`;

export const BorrowerInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 0.25rem;
  @media (min-width: 600px) {
    padding: 0 12px;
  }
  .part1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #01c0fc;
  }
  .borrower-details {
    display: flex;
    gap: 1.25rem;
    .address {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: #ffffff;
    }
    .name {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 30px;
      display: flex;
      align-items: center;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: #687d95;
    }
  }
  .about-profile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #4d647b;
  }
  .social-icons {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    .email-icon {
      font-size: 1.25rem;
      margin-top: 3px;
    }
  }
  .addresses {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 3;
    .address-header {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: #01c0fc;
    }
    .grid-part1 {
      display: grid;
      grid-template-columns: auto auto;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }
    .each-address {
      display: flex;
      gap: 0.5rem;
      .address-name {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: white;
      }
      .address-token {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
        color: #01c0fc;
      }
      .copy-icon {
        display: flex;
        align-items: center;
        align-self: center;
        color: #01c0fc;
      }
    }
  }
`;

export const Wrapper = styled.div`
  padding: 20px 4px;
  border-top: 1px solid #28333f;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 1.5rem;
  @media (min-width: 600px) {
    padding: 20px 12px;
  }
`;

export const SubPart2Content = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  gap: ${(props) => (props.totalWords === 2 ? '30px' : '65px')};
`;
export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Left = styled.div`
  width: 160px;
`;
export const Right = styled.div`
  width: 150px;
`;
export const Title = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;

  text-transform: uppercase;
  color: #4d647b;
`;
export const Description = styled.h2`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: #ffffff;
`;
export const Header = styled.div`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  -webkit-box-align: center;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02', 'ss03', 'ss04';
  color: #4d647b;
  display: inline;
`;
