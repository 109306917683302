import React, { useEffect, useState } from 'react';
import {
  Container,
  DetailLowePart,
  SecondPart,
  SubPart1,
  BorrowerInfoSection,
  Wrapper,
  Content,
  Left,
  Right,
  Title,
  Description,
  Header,
  StatusText,
  HorizentalBar,
  MiddleBar,
  SubPart2,
  DetailLowePart2,
  SubPart2Content,
} from './style';
import { HiOutlineMail } from 'react-icons/hi';
import { BiCopy } from 'react-icons/bi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useBorrowerPoolInfo } from '../../utils/markets';
import { shortAddress, truncateStr } from '../../utils/utils';
import { MdOutlineDesktopWindows } from 'react-icons/md';

import {
  getRoundValue,
  decimalUSDC,
  defaultDecimal,
  formatDateTimeInDayAndHours,
  COUPON_RATE_SCALE,
  getSymbol,
} from '../../utils/tools';
import dayjs from '../../utils/dayjs';
import axios from 'axios';
import { CREDORA_CLIENTID, CREODORA_CLIENTSECRET } from '../../utils/config';
import { CreditInfoModal } from './CreditInfoModal';

const BorrowerInfo = ({ poolDataFormat }) => {
  const [data, loaded] = useBorrowerPoolInfo();
  var nf = new Intl.NumberFormat();
  const [listCreditScore, setListCreditScore] = useState();
  const [creditScore, setCreditScore] = useState();

  useEffect(() => {
    getCreditScore();
  }, []);

  useEffect(() => {
    if (data?.poolInfo) {
      const creditScoreRes = listCreditScore.find(
        (credit) => credit?.walletAddress === data.poolInfo.owner,
      );
      console.log(listCreditScore, creditScoreRes);
      setCreditScore(creditScoreRes);
    }
  }, [data, listCreditScore]);

  const getCreditScore = async () => {
    try {
      const { data } = await axios({
        baseURL: 'https://platform.credora.io',
        url: '/api/v2/risk',
        method: 'post',
        data: [],
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          clientId: CREDORA_CLIENTID,
          clientSecret: CREODORA_CLIENTSECRET,
        },
      });
      setListCreditScore(data);
      return data;
    } catch (error) {
      return data;
    }
  };

  const calcTotalFundsRepaid = () => {
    if (data.poolInfo.isWithdrawed == 1) {
      let totalAmount = getRoundValue(
        (data.poolInfo.totalLockedAmount +
          (data.poolInfo.totalLockedAmount *
            data.poolInfo.couponRate *
            data.poolInfo.lendingPeriod) /
            (3153600000 * COUPON_RATE_SCALE)) /
          decimalUSDC,
        defaultDecimal,
      );
      return (
        totalAmount -
        getRoundValue(
          (data.poolInfo.totalClaimedAmount +
            data.poolInfo.totalClaimedInterestAmount) /
            decimalUSDC,
          defaultDecimal,
        )
      );
    } else return 0;
  };

  function countWords(str) {
    if (str) {
      const arr = str.split(' ');
      return arr.filter((word) => word !== '').length;
    } else return 1;
  }

  return (
    <Container>
      <div className="header-section">
        <h3 className="header">pool and borrower information</h3>
      </div>
      <SecondPart>
        <SubPart1>
          <div className="sub-part1">
            <text className="header-subpart1">Deposited Collateral</text>
            {
              <text className="collateral-amount">
                {poolDataFormat?.formatted?.depositedCollateral}
              </text>
            }
          </div>
          <div className="sub-part2">
            {creditScore?.creditScore ? (
              <>
                <CreditInfoModal pool={creditScore}></CreditInfoModal>
                <text className="text-subpart2">
                  {creditScore?.scoreTitle}credit Score:{' '}
                  <span className="credit-score-text">
                    {creditScore?.creditScore}
                  </span>{' '}
                </text>
              </>
            ) : (
              ''
            )}
          </div>
        </SubPart1>

        <DetailLowePart2 active={'true'}>
          <HorizentalBar sidebarColor={poolDataFormat?.formatted?.status} />
          <SubPart2Content
            style={{ marginBottom: '2px' }}
            totalWords={countWords(poolDataFormat?.formatted?.status)}
          >
            <div className="flex-grow">
              <text className="label1">Status</text>
            </div>

            <div className="flex-grow">
              <StatusText titleColor={poolDataFormat?.formatted?.status}>
                {poolDataFormat?.formatted?.status}
              </StatusText>
            </div>
          </SubPart2Content>

          <SubPart2Content
            totalWords={countWords(poolDataFormat?.formatted?.status)}
          >
            <div className="flex-grow">
              <text className="label1">Total Commited</text>
            </div>

            <div className="flex-grow">
              <text className="sub-label">
                {poolDataFormat?.formatted?.totalCommited}
              </text>
            </div>
          </SubPart2Content>
          <SubPart2Content
            totalWords={countWords(poolDataFormat?.formatted?.status)}
          >
            <div className="flex-grow">
              <text className="label1">Borrow Yield</text>
            </div>

            <div className="flex-grow">
              <text className="sub-label">
                {poolDataFormat?.formatted?.borrowYield}
              </text>
            </div>
          </SubPart2Content>
        </DetailLowePart2>
        {/* <DetailLowePart
          active={'true'}
          totalWords={countWords(poolDataFormat?.formatted?.status)}
        >
          <HorizentalBar sidebarColor={poolDataFormat?.formatted?.status} />
          <div className="div2">
            <div className="section"  >
              <text className="label1">Status</text>
              <StatusText titleColor={poolDataFormat?.formatted?.status}>
              {poolDataFormat?.formatted?.status}
              </StatusText>

              <MiddleBar
                className="side-label"
                totalWords={countWords(poolDataFormat?.formatted?.status)}
              ></MiddleBar>
            </div>
            
            <div className="section"  >
              <text className="label1">Total Commited</text>
              <text className="sub-label">
                {poolDataFormat?.formatted?.totalCommited}
              </text>
              <MiddleBar
                className="side-label"
                totalWords={countWords(poolDataFormat?.formatted?.status)}
              ></MiddleBar>
            </div>
            <div className="section">
              <text className="label1">Borrow Yield</text>
              <text className="sub-label">
                {poolDataFormat?.formatted?.borrowYield}
              </text>
            </div>
          </div>
        </DetailLowePart> */}
      </SecondPart>
      <BorrowerInfoSection>
        {data?.borrower && (
          <>
            <div className="part1">borrower information</div>
            <div className="borrower-details">
              <span className="address">{`${
                data.borrower.solanaAddress
                  ? shortAddress(data.borrower.solanaAddress)
                  : ''
              }`}</span>
              <span className="name">
                Name: {`${data.borrower.name ? data.borrower.name : 'N/A'}`}
              </span>
            </div>
            <div className="about-profile">
              About: {`: ${data.borrower.about ? data.borrower.about : 'N/A'}`}
            </div>

            {((data.borrower.email !== undefined &&
              data.borrower.email !== '') ||
              (data.borrower.discordUrl !== undefined &&
                data.borrower.discordUrl !== '') ||
              (data.borrower.twitterUrl !== undefined &&
                data.borrower.twitterUrl !== '') ||
              (data.borrower.telegramUrl !== undefined &&
                data.borrower.telegramUrl !== '') ||
              (data.borrower.website !== undefined &&
                data.borrower.website !== '')) && (
              <div className="social-icons">
                {data.borrower.email !== undefined &&
                data.borrower.email !== '' ? (
                  <a
                    href={'mailto:' + data.borrower.email}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    <HiOutlineMail className="email-icon" />
                  </a>
                ) : null}

                {data.borrower.discordUrl !== undefined &&
                data.borrower.discordUrl !== '' ? (
                  <a
                    href={`https://discordapp.com/users/${data.borrower.discordUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        'https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653370/akar-icons_discord-fill_gdrb55.svg'
                      }
                      alt="discord"
                    />
                  </a>
                ) : null}

                {data.borrower.twitterUrl !== undefined &&
                data.borrower.twitterUrl !== '' ? (
                  <a
                    href={`https://twitter.com/${data.borrower.twitterUrl}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    <img
                      src={
                        'https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653499/akar-icons_twitter-fill_vkn6xc.svg'
                      }
                      alt="twitter"
                    />{' '}
                  </a>
                ) : null}

                {data.borrower.telegramUrl !== undefined &&
                data.borrower.telegramUrl !== '' ? (
                  <a
                    href={`https://t.me/${data.borrower.telegramUrl.replace(
                      '@',
                      '',
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    <img
                      src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1658653480/akar-icons_twitter-fill_gaaszb.png"
                      alt="telegram"
                    />
                  </a>
                ) : null}

                {data.borrower.website !== undefined &&
                data.borrower.website !== '' ? (
                  <a
                    href={data.borrower.website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    <MdOutlineDesktopWindows className="email-icon" />
                  </a>
                ) : null}
              </div>
            )}

            <div className="addresses">
              <div className="address-header">Verified Ownership of</div>
              <div className="grid-part1">
                {data.borrower.addresses.length > 0
                  ? data.borrower.addresses.map((verified_address, index) => (
                      <div className="each-address">
                        <span className="address-name">
                          {verified_address?.chainData?.name}
                        </span>
                        <span className="address-token">
                          {' '}
                          {truncateStr(verified_address.address, 3)}
                        </span>
                        <CopyToClipboard
                          onCopy={() => alert('copied')}
                          text={verified_address.address}
                        >
                          <BiCopy
                            className="copy-icon"
                            style={{ cursor: 'pointer' }}
                          />
                        </CopyToClipboard>
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </>
        )}
      </BorrowerInfoSection>

      {/* <Wrapper>
        <Content>
          <Left>
            <Title>Subscription Start Date</Title>
            <Description>3-06-2022 </Description>
          </Left>
          <Right>
            <Title>Subscription Start Time</Title>
            <Description> 2:20:53 GMT</Description>
          </Right>
        </Content>

        <Content>
          <Left>
            <Title>Lending Start Date</Title>
            <Description>3-06-2022 </Description>
          </Left>
          <Right>
            <Title>Lending Start Time</Title>
            <Description> 2:20:53 GMT</Description>
          </Right>
        </Content>

        <Content>
          <Left>
            <Title>Subscription Period</Title>
            <Description>Borrowing Duration</Description>
          </Left>
          <Right>
            <Title>Borrowing Duration</Title>
            <Description> 2:20:53 GMT</Description>
          </Right>
        </Content>

        <Content>
          <Left>
            <Title>Lending Finish Date</Title>
            <Description>Borrowing Duration</Description>
          </Left>
          <Right>
            <Title>Lending Finish Time</Title>
            <Description> 2:20:53 GMT</Description>
          </Right>
        </Content>

        <Content>
          <Left>
            <Title>Max Issuance Amount:</Title>
            <Description>Borrowing Duration</Description>
          </Left>
          <Right>
            <Title>Min Issuance Amount:</Title>
            <Description> 2:20:53 GMT</Description>
          </Right>
        </Content>
      </Wrapper> */}

      {data?.poolInfo && (
        <Wrapper>
          <Header>POOL DETAILS</Header>
          <Content>
            <Left>
              <Title>POOL TOKEN ADDRESS</Title>
            </Left>
            <Right>
              <Description style={{ display: 'flex', gap: '4px' }}>
                {' '}
                {truncateStr(data.poolInfo.mintPoolToken, 5)}
                <CopyToClipboard
                  onCopy={() => alert('copied')}
                  text={data.poolInfo.mintPoolToken}
                >
                  <BiCopy
                    className=""
                    style={{
                      cursor: 'pointer',
                      color: '#01C0FC',
                      marginLeft: '2px',
                      fontSize: '16px',
                      lineHeight: '24px',
                    }}
                  />
                </CopyToClipboard>
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>SUBSCRIPTION START TIME</Title>
            </Left>
            <Right>
              <Description>
                {data.poolInfo.subscriptionStartTime
                  ? `${dayjs
                      .unix(data.poolInfo.subscriptionStartTime)
                      .utc()
                      .format('MM-DD-YYYY HH:mm:ss')} UTC`
                  : 'TBD'}
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>MAX BORROW</Title>
            </Left>
            <Right>
              <Description>
                {' '}
                {nf.format(
                  getRoundValue(
                    data.poolInfo.goal / decimalUSDC,
                    defaultDecimal,
                  ),
                )}{' '}
                USDC
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>MIN BORROW</Title>
            </Left>
            <Right>
              <Description>
                {nf.format(
                  getRoundValue(
                    data.poolInfo.min / decimalUSDC,
                    defaultDecimal,
                  ),
                )}{' '}
                USDC
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>SUBSCRIPTION PERIOD</Title>
            </Left>
            <Right>
              <Description>
                {' '}
                {formatDateTimeInDayAndHours(data.poolInfo.subscriptionPeriod)}
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>BORROW START TIME</Title>
            </Left>
            <Right>
              <Description>
                {data.poolInfo.lendingStartedTime
                  ? `${dayjs
                      .unix(data.poolInfo.lendingStartedTime)
                      .utc()
                      .format('MM-DD-YYYY HH:mm:ss')} UTC`
                  : 'TBD'}
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>BORROW DURATION</Title>
            </Left>
            <Right>
              <Description>
                {formatDateTimeInDayAndHours(data.poolInfo.lendingPeriod)}
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>POOL FINISH TIME</Title>
            </Left>
            <Right>
              <Description>
                {' '}
                {data.poolInfo.lendingStartedTime > 0
                  ? `${dayjs
                      .unix(
                        data.poolInfo.lendingStartedTime +
                          data.poolInfo.lendingPeriod,
                      )
                      .utc()
                      .format('MM-DD-YYYY HH:mm:ss')} UTC`
                  : 'TBD'}
              </Description>
            </Right>
          </Content>
        </Wrapper>
      )}
      {data?.poolInfo && (
        <Wrapper style={{ marginTop: '0px' }}>
          <Header>REPAYMENT DETAILS</Header>
          <Content>
            <Left>
              <Title>TOTAL POOL TOKEN AMOUNT</Title>
            </Left>
            <Right>
              <Description>
                {nf.format(
                  getRoundValue(
                    data.poolInfo.totalMintedAmount / decimalUSDC,
                    defaultDecimal,
                  ),
                )}
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>REMAINING LIABILITY</Title>
            </Left>
            <Right>
              <Description>
                {' '}
                {nf.format(
                  getRoundValue(
                    (data.poolInfo.totalClaimedAmount +
                      data.poolInfo.totalClaimedInterestAmount) /
                      decimalUSDC,
                    defaultDecimal,
                  ),
                )}{' '}
                USDC
              </Description>
            </Right>
          </Content>

          <Content>
            <Left>
              <Title>TOTAL FUNDS REPAID</Title>
            </Left>
            <Right>
              <Description> {calcTotalFundsRepaid()} USDC </Description>
            </Right>
          </Content>

          {/* <Content>
          <Left>
            <Title>CURRENT BORROWED AMOUNT</Title>
          </Left>
          <Right>
            <Description>  ---</Description>
          </Right>
        </Content>

        <Content>
          <Left>
            <Title>BONUS TOKEN NAME</Title>
          </Left>
          <Right>
            <Description>---</Description>
          </Right>
        </Content>

        <Content>
          <Left>
            <Title>BONUS AMOUNT</Title>
          </Left>
          <Right>
            <Description>0</Description>
          </Right>
        </Content> */}
        </Wrapper>
      )}
    </Container>
  );
};

export default BorrowerInfo;
