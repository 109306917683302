import dayjs from "./dayjs";
import {Connection, PublicKey} from "@solana/web3.js";
import {TokenInfo} from "@solana/spl-token-registry";
import {MintInfo, MintLayout, u64} from "@solana/spl-token";

const nf = new Intl.NumberFormat();

export let NETWORK_RPC = process.env.REACT_APP_NETWORK_RPC;
export const SB_MINT_ADDRESS =
    NETWORK_RPC === "devnet"
        ? "BNrHCQrLQqkmLvdW5a6hLNLXECHoY3cM2qtRjZz8Vrn5"
        : "8i3dM6LQiHqVsFCES6y5rGAVXEiPiH9nTDAsTqGpmrTC";
export const USDC_MINT_ADDRESS =
    NETWORK_RPC === "devnet"
        ? "9LtdQD7tzQnBrbXTHojiUMn8CUub3PaugsSxxwH7G3Ca"
        : "DXdV1fxec5HwkvNieXE4bR7LTSBo4EGxVq4RmW8RsnYE";

export const decimalUSDC = 1000000; // USDC decimal value
export const decimalSB = 1000000; // LNDR decimal value

export const decimalSOL = 1000000000; // SOL decimal value
export const MIN_MARKET_FEE = 2800000000;

export const decimalNumberUSDC = 6;
export const COUPON_RATE_SCALE = 10000;

export const AWAITING_FOR_SUBSCRIPTION = 0;
export const SUBSCRIPTION_PERIOD = 1;
export const INVALID_POOL = 2;
export const LENDING_PERIOD = 3;
export const EXTRA_PERIOD = 4;
export const LENDING_EXPIRED = 5;
export const FINISHED_POOL = 6;

export const tokensData = [
    { name: "custom", address:"", symbol: "Custom Token", decimals: 0},
    { name: "LNDR", address: "BNrHCQrLQqkmLvdW5a6hLNLXECHoY3cM2qtRjZz8Vrn5", symbol: "LNDR", decimals: 6},
    { name: "solETH", address: "2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk", symbol: "solETH", decimals: 6},
    { name: "solBTC", address: "Wbt2CgkkD3eVckD5XxWJmT8pTnFTyWrwvGM7bUMLvsM", symbol: "solBTC", decimals: 6},
    { name: "RAY", address: "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R", symbol: "RAY", decimals: 6},
];

export async function reformatPoolData(connection: Connection, poolData: any, tokenMap: Map<string, TokenInfo>) {
    const poolStateText = getPoolStateText(poolData.state, poolData);
    const decimal = await getDecimal(connection, poolData.collateralToken);
    return {
        source: poolData,
        formatted: {
            depositedCollateral: `${poolData.collateralAmount && (decimal !== undefined) ? nf.format(poolData.collateralAmount / decimal) : 0} ${decimal==1 ?"NFT":getSymbol(poolData.collateralToken, tokenMap)}`,
            status: poolStateText,
            totalCommited: `${poolData.totalLockedAmount ? nf.format(getRoundValue(poolData.totalLockedAmount / decimalUSDC, defaultDecimal)) : 0} USDC`,
            borrowYield: `${poolData.couponRate / COUPON_RATE_SCALE}%`,
            borrowPeriod: formatDateTimeInDayAndHours(poolData.lendingPeriod)
        }
    };
}

export async function getDecimal(connection: Connection, pubKey: string) {
    const decimal = await getMintDecimalValue(connection, new PublicKey(pubKey));
    if (decimal) return decimal;
    else return undefined;
}

export const getMintDecimalValue = async (connection: Connection, mintAddr: PublicKey) => {
    const mintInfo = await getMintInfo(connection, mintAddr);
    const precision = Math.pow(10, mintInfo?.decimals || 0);
    return precision;
};
const getMintInfo = async (connection: Connection, pubKey: PublicKey) => {
    const info = await connection.getAccountInfo(pubKey);
    if (info === null) throw new Error("Failed to find mint account");

    const data = Buffer.from(info.data);
    return deserializeMint(data);
};
const deserializeMint = (data: Buffer) => {
    if (data.length !== MintLayout.span) throw new Error("Not a valid Mint");

    const mintInfo = MintLayout.decode(data);
    if (mintInfo.mintAuthorityOption === 0) {
        mintInfo.mintAuthority = null;
    } else {
        mintInfo.mintAuthority = new PublicKey(mintInfo.mintAuthority);
    }
    mintInfo.supply = u64.fromBuffer(mintInfo.supply);
    mintInfo.isInitialized = mintInfo.isInitialized !== 0;
    if (mintInfo.freezeAuthorityOption === 0) {
        mintInfo.freezeAuthority = null;
    } else {
        mintInfo.freezeAuthority = new PublicKey(mintInfo.freezeAuthority);
    }
    return mintInfo as MintInfo;
};


export const getSymbol = (pk : string, tokenMap: Map<string, TokenInfo>) => {
    if (pk === SB_MINT_ADDRESS)
        return "LNDR";
    else if (pk === USDC_MINT_ADDRESS)
        return "USDC";
    else if (tokenMap.get(pk))
        return tokenMap.get(pk)?.symbol;
    else
        return "";
}

export const getPoolStateText = (state: number, poolInfo: any) => {
    let poolStateText = "Subscription Period";

    //Todo cancel by borrower
    if ((poolInfo.cancelledState > 0) && (state !== FINISHED_POOL)) {
        return "Cancelled";
    } else {
        if (state === AWAITING_FOR_SUBSCRIPTION)
            return "Pre Subscription";
        else if (state === SUBSCRIPTION_PERIOD)
            return "Active Subscription";
        else if (state === INVALID_POOL)
            return "Unfunded";
        else if (state ===  LENDING_PERIOD)
            return "Funded";
        else if (state ===  EXTRA_PERIOD)
            return "Grace Period";
        else if (state === LENDING_EXPIRED) {
            if (poolInfo.totalLockedAmount < poolInfo.min)
                return "Unfunded";
            else if (equal_with_epsilon_error(poolInfo.totalClaimedAmount, 0, decimalNumberUSDC) && equal_with_epsilon_error(poolInfo.totalClaimedInterestAmount, 0, decimalNumberUSDC))
                return "Successful";
            else
                return "Defaulted";
        } else if (state == FINISHED_POOL) {
            if (poolInfo.cancelledState > 0)
                return "Cancelled";
            else if (poolInfo.totalLockedAmount < poolInfo.min)
                return "Unfunded";
            else if (equal_with_epsilon_error(poolInfo.totalClaimedAmount, 0, decimalNumberUSDC) && equal_with_epsilon_error(poolInfo.totalClaimedInterestAmount, 0, decimalNumberUSDC))
                return "Successful";
            else
                return "Defaulted";
        }
    }
    return poolStateText;
};

export const equal_with_epsilon_error = (a: number, b: number, exp: number) => {
    let delta: number;
    if (a > b) {
        delta = a - b;
    } else {
        delta = b - a;
    }
    let epsilon_error = (exp < precise.length) ? precise[exp] : 10 ** exp;
    return delta < epsilon_error;
}

export const truncateStr = (str: string, n: number) => {
    if (!str) return "";
    return str.length > n
        ? str.substr(0, n - 1) + "..." + str.substr(str.length - n, str.length - 1)
        : str;
};

export function convertLastAddress(address: string){
    return (address) ? address.substring(address.length - 5) : '';
}

export function convertAddress(address: string){
    return (address) ? address.substring(0, 3) + '...' + address.substring(address.length - 2) : '';
}

export const formatTime = (timeData: number) => {
    return (timeData) ? `${dayjs.unix(timeData).utc().format("MM-DD-YYYY HH:mm:ss")} UTC` : 'TBD';
}

export const formatDateTimeInDayAndHours = (timeSubscription: number): string => {
    let ret = "";
    const timeConverted = secondsToTime(timeSubscription);
    ret = `${timeConverted.d}D:${timeConverted.h}H:${timeConverted.m}M`;
    return ret;
}

export const secondsToTime = (secs:number) =>{
    let days = Math.floor(secs / (24*60 * 60));

    let secsNew = secs - days * (24*60*60);
    let hours = Math.floor(secsNew / (60 * 60));

    let divisor_for_minutes = secsNew % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
        "d": days,
        "h": twoDigit(hours),
        "m": twoDigit(minutes),
        "s": twoDigit(seconds)
    };
    return obj;
}
export const twoDigit = (myNumber: number)=> {
    return ("0" + myNumber).slice(-2);
};

const precise: number[] = [1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000]
export const defaultDecimal = 3;
export const getRoundValue = (value: number, decimal: number) => {
    let ps = (decimal < precise.length) ? precise[decimal] : 10 ** decimal;
    return Math.floor(value * ps) / ps;
};