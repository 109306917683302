import styled from 'styled-components';

export const Container = styled.div`
  /* position: relative; */
  font-family: 'Space Grotesk';
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  background: #1a232b;
  height: calc(100% - 3px);
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .topbar {
    height: 62px;
    background: #1a232b;
    position: sticky;
    top: 0;
    width: 100%;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    z-index: 10;
    .header {
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      color: #ffffff;
    }

    .tableHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-header,
      .right-header,
      .center-header {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 195% */

        display: flex;
        align-items: center;
        text-align: right;
        text-transform: uppercase;

        /* backgrounds/G3 */

        color: #687d95;
      }
      .right-header {
        /* padding-right: 5px; */
      }
      .center-header {
        padding-right: 20px;
      }
      @media (max-width: 1530px) {
        font-size: 10px;
      }
    }
  }

  &::-webkit-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .table {
    /* margin-top: 62px; */
    width: 96%;
    border-radius: 6px;
    margin: 0 2% 0 2%;
    border-collapse: collapse;

    overflow: hidden;
    tr:nth-child(odd) {
      background: #161d23;
    }
    tr:nth-child(even) {
      background: #12181f;
    }
    tbody {
      border-radius: 10px;
    }
    td {
      height: 21px;
    }

    .left-text {
      text-align: left;
      color: #687d95;
      font-size: 12.6822px;
      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      padding-left: 12px;
      height: 21px;
    }

    .center-text {
      text-align: left;
      color: #687d95;
      font-size: 12.6822px;
      color: #687d95;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      padding-left: 48px;
      height: 21px;
    }

    .right-text {
      text-align: right;
      color: #687d95;
      font-size: 12.6822px;

      color: #687d95;

      font-family: 'Space Grotesk';
      font-style: normal;
      font-weight: 500;

      letter-spacing: 0.3px;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
      padding-right: 12px;
      height: 21px;
    }

    @media (max-width: 1530px) {
      .right-text,
      .center-text,
      .left-text {
        font-size: 11px;
      }
    }
  }
`;
