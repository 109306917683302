import styled from 'styled-components';

export const Container = styled.div``;
export const TopButtons = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const ButtonBuy = styled.button.attrs(
  (props: { active: String }) => props,
)`
  position: relative;
  width: 149px;
  height: 50px;
  background: ${({ active }) =>
    active === 'buy'
      ? `linear-gradient(
      0deg,
      rgba(37, 255, 59, 0.3) -25.45%,
      rgba(40, 51, 63, 0) 79.09%
    ),
    #1f2934`
      : '#1A232B'};
  border-radius: 6px 0px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  /* or 250% */
  transition: 0.5s;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* pool/funded */

  color: ${({ active }) => (active === 'buy' ? '#25ff3b' : '#687D95')};
`;

export const ActiveLink = styled.div.attrs(
  (props: { active: String }) => props,
)`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 93.75%;
  /* bottom: -6.25%; */

  height: 3px;
  bottom: 0;
  /* pool/funded */

  background: ${(props) => (props.active === 'buy' ? '#25FF3B' : 'none')};
  /* funded glow */

  box-shadow: ${(props) =>
    props.active === 'buy'
      ? '0px 1px 6px 2px rgba(44, 221, 62, 0.25)'
      : 'none'};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  z-index: 1;
`;

export const ButtonSell = styled.button.attrs(
  (props: { active: String }) => props,
)`
  position: relative;
  width: 149px;
  height: 50px;
  background: ${({ active }) =>
    active === 'sell'
      ? `linear-gradient(
        0deg,
        #613138 -25.45%,
        #1F2934 79.09%
      ),
      #1f2934`
      : '#1A232B'};
  transition: 0.5s;
  border-radius: 6px 0px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;
  /* or 250% */

  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* pool/funded */

  color: ${({ active }) => (active === 'sell' ? '#EF4444' : '#687D95')};
`;

export const ActiveLinkSell = styled.div.attrs(
  (props: { active: String }) => props,
)`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 93.75%;
  /* bottom: -6.25%; */

  height: 3px;
  bottom: 0;
  /* pool/funded */

  background: ${(props) => (props.active === 'sell' ? '#EF4444' : 'none')};
  /* funded glow */

  box-shadow: ${(props) =>
    props.active === 'sell' ? '0px 1px 6px 2px rgba(40,68,68,0.25)' : 'none'};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  z-index: 1;
`;

export const ContentWrapper = styled.div`
  padding: 11px;
  display: grid;
  row-gap: 19px;
`;
export const PriceWrapper = styled.div.attrs(
  (props: { active: String }) => props,
)`
  width: 100%;
  height: 40px;
  border: 1px solid #1a232b;
  background: #161d23;

  border-radius: 6px;
  display: flex;
  align-items: center;

  overflow: hidden;
  transition: 0.4s;

  &:hover {
    border: ${({ active }) =>
      active === 'buy'
        ? `1px solid rgba(37, 255, 59, 0.5)`
        : '1px solid rgba(239, 68, 68, .5)'};
  }

  &:focus-within {
    border: ${({ active }) =>
      active === 'buy' && `1px solid rgba(37, 255, 59, 0.5)`};

    box-shadow: ${({ active }) =>
      active === 'buy'
        ? `0px 2px 10px -1px rgba(37, 255, 59, 0.5)`
        : '0px 2px 10px -1px rgba(239, 68, 68, .5)'};
  }
`;

export const PriceBtn = styled.div`
  width: 75.6px;

  height: 40px;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;

  /* identical to box height, or 273% */
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* White */

  color: #ffffff;
  /* backgrounds/B4 */

  background: #1f2934;
  border-radius: 6px 0px 0px 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  height: 40px;
`;

export const PriceInput = styled.input`
  width: 100%;
  height: 40px;

  border: none;
  outline: none;
  background: #161d23;
  color: #ffffff;
  padding-left: 12px;

  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-transform: uppercase;

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const RightContent = styled.h4`
  position: relative;
  width: 120px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: flex-end;
  padding-right: 11px;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  background: #161d23;

  /* or 273% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* backgrounds/G2 */

  color: #4d647b;
`;
export const VerticleLine = styled.div`
  position: absolute;
  width: 18px;
  height: 0px;
  left: 20px;
  top: 20px;

  /* backgrounds/B5 */
  transform: rotate(90deg);
  border: 1px solid #28333f;
`;

export const SizeWrapper = styled(PriceWrapper).attrs(
  (props: { active: String }) => props,
)`
  &:hover {
    border: ${({ active }) =>
      active === 'sell' && '1px solid rgba(239, 68, 68, .5)'};
  }

  &:focus-within {
    border: ${({ active }) =>
      active === 'sell' && `1px solid rgba(239, 68, 68, .5)`};

    box-shadow: ${({ active }) =>
      active === 'sell' && `0px 2px 10px -1px rgba(239, 68, 68, .5)`};
  }
`;

export const SizeBtn = styled(PriceBtn)`
  width: 98.92px;
`;

export const SizeInputWrapper = styled.div`
  display: flex;
  height: 40px;
`;
export const Left = styled.div`
  display: flex;
  position: relative;
`;
export const Right = styled.div`
  display: flex;
`;

export const Desc = styled.h4`
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: flex-end;
  padding-right: 11px;

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  background: #161d23;

  /* or 273% */

  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* backgrounds/G2 */

  color: #4d647b;
  background: #161d23;
`;

export const DescSb1 = styled(Desc)`
  padding-right: 8px;
`;

export const LeftInput = styled(PriceInput)`
  flex: 1;
`;
export const RightInput = styled(PriceInput)`
  flex: 1;
`;

export const SbVerticleLine = styled.div`
  position: absolute;
  width: 18px;
  height: 0px;
  right: -8px;
  top: 20px;

  /* backgrounds/B5 */
  transform: rotate(90deg);
  border: 1px solid #28333f;
`;

export const ProgressWrapper = styled.div`
  display: flex;
`;
export const ProgressStatus = styled.div`
  flex: 1;
  /* width: 69px; */
  height: 24px;

  /* backgrounds/B5 */
  background: #28333f;
  /* backgrounds/B3 */
  border: 1px solid #1a232b;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Status = styled.h3`
  /* app-heading/H5 */

  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 30px;
  /* or 273% */
  margin-bottom: 0;

  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: #abbdd1;
  border-radius: 4px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;
  .ant-switch-checked {
    background: #01c0fc;
  }
  .ant
  .ant-switch:focus {
     
  }
  .ant-switch {
    display: flex;
    align-items: center;
    //background: #01c0fc;
    

    .ant-switch-handle {
    
      background: white;
      border-radius: 50%;
    }

    .ant-switch-handle:before {
 
      top: 0;
      background: white;
      border-radius: 50%;
    }
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const Text = styled.h5`
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 30px;
  /* identical to box height, or 273% */

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;

  /* White */

  color: #ffffff;
`;

export const Button = styled.button.attrs((props: { active: String }) => props)`
  display: flex;

  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  display: block;
  transition: 0.5s;

  gap: 10px;
  height: 41px;

  /* buttons/green */

  background: ${({ active }) =>
    active === 'buy' ? 'rgba(37, 255, 59, 0.3)' : 'rgba(239, 68, 68, 0.3)'};
  /* pool/funded */

  border: 2px solid
    ${({ active }) => (active === 'buy' ? '#25ff3b' : '#EF4444')};
  border-radius: 6px;

  &:hover {
    background: transparent;
    box-shadow: ${({ active }) =>
      active === 'buy'
        ? '0px 2px 10px -1px rgba(37, 255, 59, 0.5)'
        : '0px 2px 10px -1px rgba(239, 68, 68, .5)'};
  }

  &:hover .buttonLeft {
    padding-right: 10px;
  }

  &:hover .buttonRight {
    opacity: 1;
    padding-left: 5px;

    /* transform: translateX(-10px); */
  }
`;

export const RightArrow = styled.div.attrs(
  (props: { active: String }) => props,
)`
  display: block;
  position: absolute;
  top: 9px;
  left: 170px;

  opacity: 0;
  transition: all 0.3s ease-in;

  svg {
    fill: ${({ active }) => (active === 'buy' ? '#25FF3B' : '#EF4444')};
    transform: rotate(45deg);
  }
`;

export const SpanText = styled.span`
  display: block;
  transition: 0.5s;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on;
  color: #ffffff;
  cursor: pointer;

  transition: all 0.3s ease-in;
`;
