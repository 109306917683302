import React, { useEffect, useState } from 'react';
import {
  Container,
  TopButtons,
  ButtonBuy,
  ButtonSell,
  ActiveLink,
  ActiveLinkSell,
  ContentWrapper,
  PriceWrapper,
  SizeWrapper,
  ToggleWrapper,
  Button,
  PriceBtn,
  PriceInput,
  InputWrapper,
  RightContent,
  VerticleLine,
  SizeBtn,
  SizeInputWrapper,
  Left,
  Desc,
  Right,
  LeftInput,
  RightInput,
  DescSb1,
  SbVerticleLine,
  ProgressWrapper,
  ProgressStatus,
  Status,
  SpanText,
  RightArrow,
  SwitchWrapper,
  Text,
} from './styles';
import {
  useFeeDiscountKeys,
  useLocallyStoredFeeDiscountKey,
  useMarket,
  useMarkPrice,
  useSelectedBaseCurrencyAccount,
  useSelectedBaseCurrencyBalances,
  useSelectedOpenOrdersAccount,
  useSelectedQuoteCurrencyAccount,
  useSelectedQuoteCurrencyBalances,
} from '../../utils/markets';
import { useWallet } from '../../utils/wallet';
import { notify } from '../../utils/notifications';
import {
  floorToDecimal,
  getDecimalCount,
  roundToDecimal,
} from '../../utils/utils';
import { useSendConnection } from '../../utils/connection';

import { getUnixTs, placeOrder } from '../../utils/send';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import { refreshCache } from '../../utils/fetch-loop';
import tuple from 'immutable-tuple';

import { Switch } from 'antd';

const BuySell = ({ setChangeOrderRef }) => {
  const [side, setSide] = useState<'buy' | 'sell'>('buy');

  const { baseCurrency, quoteCurrency, market } = useMarket();
  const baseCurrencyBalances = useSelectedBaseCurrencyBalances();
  const quoteCurrencyBalances = useSelectedQuoteCurrencyBalances();
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount();
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount();
  const openOrdersAccount = useSelectedOpenOrdersAccount(true);
  const { wallet, connected } = useWallet();
  const sendConnection = useSendConnection();
  const markPrice = useMarkPrice();
  useFeeDiscountKeys();
  const { storedFeeDiscountKey: feeDiscountKey } =
    useLocallyStoredFeeDiscountKey();

  const [postOnly, setPostOnly] = useState(false);
  const [ioc, setIoc] = useState(false);
  const [baseSize, setBaseSize] = useState<number | any>(undefined);
  const [quoteSize, setQuoteSize] = useState<number | any>(undefined);
  const [price, setPrice] = useState<number | any>(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [sizeFraction, setSizeFraction] = useState(0);

  useEffect(() => {
    setChangeOrderRef && setChangeOrderRef(doChangeOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChangeOrderRef]);

  const availableQuote =
    openOrdersAccount && market
      ? market.quoteSplSizeToNumber(openOrdersAccount.quoteTokenFree)
      : 0;

  let quoteBalance = (quoteCurrencyBalances || 0) + (availableQuote || 0);
  let baseBalance = baseCurrencyBalances || 0;
  let sizeDecimalCount =
    market?.minOrderSize && getDecimalCount(market.minOrderSize);
  let priceDecimalCount = market?.tickSize && getDecimalCount(market.tickSize);

  const publicKey = wallet?.publicKey;

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  useEffect(() => {
    baseSize && price && onSliderChange(sizeFraction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [side]);

  useEffect(() => {
    updateSizeFraction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, baseSize]);

  useEffect(() => {
    const warmUpCache = async () => {
      try {
        if (!wallet || !publicKey || !market) {
          console.log(`Skipping refreshing accounts`);
          return;
        }
        const startTime = getUnixTs();
        console.log(`Refreshing accounts for ${market.address}`);
        await market?.findOpenOrdersAccountsForOwner(sendConnection, publicKey);
        await market?.findBestFeeDiscountKey(sendConnection, publicKey);
        const endTime = getUnixTs();
        console.log(
          `Finished refreshing accounts for ${market.address} after ${
            endTime - startTime
          }`,
        );
      } catch (e) {
        console.log(`Encountered error when refreshing trading accounts: ${e}`);
      }
    };
    warmUpCache();
    const id = setInterval(warmUpCache, 30_000);
    return () => clearInterval(id);
  }, [market, sendConnection, wallet, publicKey]);

  const onSetBaseSize = (baseSize: number | any) => {
    
    setBaseSize(baseSize);
    if (!baseSize) {
      setQuoteSize('');
      return;
    }
    let usePrice = price || markPrice;
    if (!usePrice) {
      setQuoteSize('');
      return;
    }
    const rawQuoteSize = baseSize * usePrice;
    const quoteSize=baseSize && roundToDecimal(rawQuoteSize, sizeDecimalCount);
    setQuoteSize(quoteSize);
  };

  const onSetQuoteSize = (quoteSize: number | undefined) => {
    setQuoteSize(quoteSize);
    if (!quoteSize) {
      setBaseSize(undefined);
      return;
    }
    let usePrice = price || markPrice;
    if (!usePrice) {
      setBaseSize(undefined);
      return;
    }
    const rawBaseSize = quoteSize / usePrice;
    const baseSize = quoteSize && roundToDecimal(rawBaseSize, sizeDecimalCount);
    setBaseSize(baseSize);
  };

  const doChangeOrder = ({
    size,
    price,
  }: {
    size?: number;
    price?: number;
  }) => {
     
    const formattedSize = size && roundToDecimal(size, sizeDecimalCount);
    const formattedPrice = price && roundToDecimal(price, priceDecimalCount);
    formattedSize && onSetBaseSize(formattedSize);
    formattedPrice && setPrice(formattedPrice);
  };

  const updateSizeFraction = () => {
    const rawMaxSize =
      side === 'buy' ? quoteBalance / (price || markPrice || 1) : baseBalance;
    const maxSize = floorToDecimal(rawMaxSize, sizeDecimalCount);
    const sizeFraction = Math.min(((baseSize || 0) / maxSize) * 100, 100);
    setSizeFraction(sizeFraction);
  };

  const onSliderChange = (value) => {
    if (!price && markPrice) {
      let formattedMarkPrice: number | string = priceDecimalCount
        ? markPrice.toFixed(priceDecimalCount)
        : markPrice;
      setPrice(
        typeof formattedMarkPrice === 'number'
          ? formattedMarkPrice
          : parseFloat(formattedMarkPrice),
      );
    }

    let newSize;
    if (side === 'buy') {
      if (price || markPrice) {
        newSize = ((quoteBalance / (price || markPrice || 1)) * value) / 100;
      }
    } else {
      newSize = (baseBalance * value) / 100;
    }

    // round down to minOrderSize increment
    let formatted = floorToDecimal(newSize, sizeDecimalCount);

    onSetBaseSize(formatted);
  };

  const postOnChange: SwitchChangeEventHandler = (checked) => {
    if (checked) {
      setIoc(false);
    }
    setPostOnly(checked);
  };
  const iocOnChange: SwitchChangeEventHandler = (checked) => {
    if (checked) {
      setPostOnly(false);
    }
    setIoc(checked);
  };

  async function onSubmit() {
    if(!connected){
      notify({
        message: 'Wallet is not connected',
        type: 'error',
      });
      return;
    }
    else if (!price) {
      console.warn('Missing price');
      notify({
        message: 'Missing price',
        type: 'error',
      });
      return;
    } else if (!baseSize) {
      console.warn('Missing size');
      notify({
        message: 'Missing size',
        type: 'error',
      });
      return;
    }

    setSubmitting(true);
    try {
      if (!wallet) {
        
        return null;
      }

      await placeOrder({
        side,
        price,
        size: baseSize,
        orderType: ioc ? 'ioc' : postOnly ? 'postOnly' : 'limit',
        market,
        connection: sendConnection,
        wallet,
        baseCurrencyAccount: baseCurrencyAccount?.pubkey,
        quoteCurrencyAccount: quoteCurrencyAccount?.pubkey,
        feeDiscountPubkey: feeDiscountKey,
      });
      refreshCache(tuple('getTokenAccounts', wallet, connected));
      setPrice('');
      onSetBaseSize('');
    } catch (e: any) {
      console.warn(e);
      if (e instanceof (Error)) {
      notify({
        message: 'Error placing order',
        description: e.message,
        type: 'error',
      });
      }
    } finally {
      setSubmitting(false);
    }
  }
 
  return (
    <Container>
      <TopButtons>
        <ButtonBuy active={side} onClick={() => setSide('buy')}>
          Buy
          <ActiveLink active={side} />
        </ButtonBuy>
        <ButtonSell active={side} onClick={() => setSide('sell')}>
          Sell
          <ActiveLinkSell active={side} />
        </ButtonSell>
      </TopButtons>

      <ContentWrapper>
        <PriceWrapper className="price-wrapper" active={side}>
          <PriceBtn>Price</PriceBtn>
          <InputWrapper>
            <PriceInput
              value={price}
              type="number"
              step={market?.tickSize || 1}
              onChange={(e) => setPrice(parseFloat(e.target.value))}
            />
            <RightContent>
              <VerticleLine />
              {quoteCurrency?quoteCurrency:'-'}
            </RightContent>
          </InputWrapper>
        </PriceWrapper>

        <PriceWrapper className="price-wrapper" active={side}>
          <PriceBtn>Size</PriceBtn>
          <InputWrapper>
            <PriceInput
               value={baseSize}
               type="number"
               step={market?.minOrderSize || 1}
               onChange={(e) => onSetBaseSize(parseFloat(e.target.value))}
            />
            <RightContent>
              <VerticleLine />
              {baseCurrency?baseCurrency:"-"}
            </RightContent>
          </InputWrapper>
        </PriceWrapper>

        <PriceWrapper className="price-wrapper" active={side}>
          <PriceBtn>Size</PriceBtn>
          <InputWrapper>
            <PriceInput
             value={quoteSize}
             type="number"
             step={market?.minOrderSize || 1}
             onChange={(e) => onSetQuoteSize(parseFloat(e.target.value))}
            />
            <RightContent>
              <VerticleLine />
              {quoteCurrency?quoteCurrency:'-'}
            </RightContent>
          </InputWrapper>
        </PriceWrapper>

        {/* <SizeWrapper active={side}>
          <SizeBtn>Size</SizeBtn>

          <SizeInputWrapper>
            <Left>
              <LeftInput  
                       value={baseSize}
                       type="number"
                       step={market?.minOrderSize || 1}
                       onChange={(e) => onSetBaseSize(parseFloat(e.target.value))}
              />
              <DescSb1>{baseCurrency?baseCurrency:"-"}</DescSb1>
              <SbVerticleLine />
            </Left>
            <Right>
              <RightInput
              value={quoteSize}
              type="number"
              step={market?.minOrderSize || 1}
              onChange={(e) => onSetQuoteSize(parseFloat(e.target.value))}/>
              <Desc> {quoteCurrency?quoteCurrency:'-'}</Desc>
            </Right>
          </SizeInputWrapper>
        </SizeWrapper> */}

        {/* <ProgressWrapper>
          <ProgressStatus>
            <Status>25%</Status>
          </ProgressStatus>
          <ProgressStatus>
            <Status>50%</Status>
          </ProgressStatus>
          <ProgressStatus>
            <Status>75%</Status>
          </ProgressStatus>
          <ProgressStatus>
            <Status>100%</Status>
          </ProgressStatus>
        </ProgressWrapper> */}
        <ToggleWrapper>
          <SwitchWrapper>
            <Switch checked={postOnly} onChange={postOnChange}/>
            <Text>Post</Text>
          </SwitchWrapper>
          <SwitchWrapper>
            <Switch  checked={ioc} onChange={iocOnChange} /> 
            <Text>Ioc</Text>
          </SwitchWrapper>
        </ToggleWrapper>
        <Button onClick={onSubmit} active={side}>
          <SpanText className="buttonLeft">
            {side === 'buy' ? 'Buy' : 'Sell'} {baseCurrency?(baseCurrency):''} 
          </SpanText>
        

          <RightArrow active={side} className="buttonRight">
            <svg
              fill="rgba(1, 192, 252, .8)"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <polygon points="7 7 15.586 7 5.293 17.293 6.707 18.707 17 8.414 17 17 19 17 19 5 7 5 7 7" />
            </svg>
          </RightArrow>
        </Button>
      </ContentWrapper>
    </Container>
  );
};

export default BuySell;
