import BalancesTable from './BalancesTable';
import OpenOrderTable from './OpenOrderTable';
import React from 'react';
import { Popover, Tabs /* , Typography */ } from 'antd';
import FillsTable from './FillsTable';
import AllOpenOrderTable from './AllOpenOrderTable';

// import FloatingElement from '../layout/FloatingElement';
import FeesTable from './FeesTable';
import { useOpenOrders, useBalances, useMarket,  useAllMarkets,
  useAllOpenOrders,
  useMarketInfos, } from '../../utils/markets';
import { Wrapper } from './style';
import { OrderWithMarketAndMarketName } from '../../utils/types';
import { useWallet } from '../../utils/wallet';
import WalletConnect from '../../components/WalletConnect';
import { convertAddress, convertLastAddress } from '../../utils/tools';
import {useInterval} from "../../utils/useInterval";
import CircleInfo from '../../assets/circle-info.svg';
// const { Paragraph } = Typography;
const { TabPane } = Tabs;

export default function TableView() {
  const {baseCurrency, marketName } = useMarket();
  return (
    <Wrapper minheight="500px" type="sell">
      <div className="info-icon">
        <div className="info-wrapper">
          <Popover
            // content={endpoint}
            placement="bottomRight"
            title="URL"
            trigger="hover"
          >
            {/* <InfoCircleOutlined style={{ color: '#01C0FC' }} /> */}

            <img
              style={{
                width: '17',
                height: '16px',

                cursor: 'pointer',
              }}
              src={CircleInfo}
              alt=""
            />
          </Popover>
        </div>
      </div>
      <Tabs defaultActiveKey="orders">
        <TabPane tab={`Open Orders(${baseCurrency})`} key="orders">
          <OpenOrdersTab />
        </TabPane>
        <TabPane tab="Trade History" key="fills">
          <FillsTable />
        </TabPane>
        <TabPane tab="Balances" key="balances">
          <BalancesTab />
        </TabPane>
        <TabPane tab="All Open Orders" key="allOpenOrders">
          <AllOpenOrdersTab />
        </TabPane>
        {/* {(market && market.supportsSrmFeeDiscounts) || true ? (
          <TabPane tab="Fee discounts" key="fees">
            <FeesTable />
          </TabPane>
        ) : null} */}
      </Tabs>
    </Wrapper>
  );
}

const OpenOrdersTab = () => {
  let openOrders = useOpenOrders();
  if (openOrders) {
    openOrders = openOrders.map((item) => {
      return {
        ...item,
        marketName: item.marketName,
      };
    });
  }
  return <OpenOrderTable openOrders={openOrders} />;
};


const AllOpenOrdersTab = () => {
  const { connected } = useWallet();
  const { openOrders, loaded, refreshOpenOrders } = useAllOpenOrders();
  let marketInfos = useMarketInfos();
  let marketAddressesToNames = Object.fromEntries(
    marketInfos.map((info) => [info.address.toBase58(), info.name]),
  );
  let [allMarkets] = useAllMarkets();
  const marketsByAddress = Object.fromEntries(
    (allMarkets || []).map((marketInfo) => [
      marketInfo.market.address.toBase58(),
      marketInfo.market,
    ]),
  );

  const dataSource: OrderWithMarketAndMarketName[] = (openOrders || [])
    .map((orderInfos) => {
        return orderInfos.orders.map((order) => {
            return {
                marketName: marketAddressesToNames[orderInfos.marketAddress],
                market: marketsByAddress[orderInfos.marketAddress],
                ...order,
            };
        });
    }
    )
    .flat();

  useInterval(refreshOpenOrders, 5*60000);
  return <AllOpenOrderTable openOrders={dataSource} />;
};


const BalancesTab = () => {
  const balances = useBalances();

  return (
    <BalancesTable
      showMarket={null}
      hideWalletBalance={null}
      onSettleSuccess={null}
      balances={balances}
    />
  );
};

const dummyData = [
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 12,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'sell',
    size: 111,
    price: 32,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 45,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'sell',
    size: 111,
    price: 54,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
  {
    marketName: 'sb1/sb2 v4',
    side: 'buy',
    size: 111,
    price: 21,
  },
];
