import React ,{useState} from 'react';
import {
  ContentWrapper,
  ContentDetails,
  TopContent,
  Heading,
  Hr,
  Info,
  MiddleContent,
  BottomContent,
  TextContent,
  Text,
  ButtonWrapper,
  CopyText,
  Description,
  Deposit,
  Settle,
  AllWallLinK,
  Span,
} from './styles';
import SbInfoImg from '../../assets/sb-one-info.svg';
import { Select } from 'antd';
import CopyImg from '../../assets/copy-text.svg';
import {Popover} from 'antd';
import Link from '../Link';
import {
  useBalances,
  useMarket,
  useSelectedBaseCurrencyAccount,
  useSelectedOpenOrdersAccount,
  useSelectedQuoteCurrencyAccount,
  useTokenAccounts,
} from '../../utils/markets';
import styled from 'styled-components';
import DepositDialog from '../DepositDialog';
import { useWallet } from '../../utils/wallet';
import { settleFunds } from '../../utils/send';
import { useSendConnection } from '../../utils/connection';
import { notify } from '../../utils/notifications';
import { Balances } from '../../utils/types';
import LinkAddress from '../LinkAddress';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useInterval } from '../../utils/useInterval';
import { useLocalStorageState } from '../../utils/utils';
import { AUTO_SETTLE_DISABLED_OVERRIDE } from '../../utils/preferences';
import { useReferrer } from '../../utils/referrer';
import StandaloneTokenAccountsSelect from "./tokenAccount"
const Tip = styled.p`
  font-size: 12px;
  padding-top: 6px;
`;


const SbOneInfo = () => {
  var nf = new Intl.NumberFormat();
  const { baseCurrency, quoteCurrency, market } = useMarket();
  const balances = useBalances();
  const openOrdersAccount = useSelectedOpenOrdersAccount(true);
  const connection = useSendConnection();
  const { providerUrl, providerName, wallet, connected } = useWallet();
  const [baseOrQuote, setBaseOrQuote] = useState('');
  const baseCurrencyAccount = useSelectedBaseCurrencyAccount();
  const quoteCurrencyAccount = useSelectedQuoteCurrencyAccount();
  const [tokenAccounts] = useTokenAccounts();
  const baseCurrencyBalances =
    balances && balances.find((b) => b.coin === baseCurrency);
  const quoteCurrencyBalances =
    balances && balances.find((b) => b.coin === quoteCurrency);
  const [autoSettleEnabled] = useLocalStorageState('autoSettleEnabled', true);
  const [lastSettledAt, setLastSettledAt] = useState<number>(0);
  const { usdcRef, usdtRef } = useReferrer();
  async function onSettleFunds() {
    if (!wallet) {
      notify({
        message: 'Wallet not connected',
        description: 'wallet is undefined',
        type: 'error',
      });
      return;
    }

    if (!market) {
      notify({
        message: 'Error settling funds',
        description: 'market is undefined',
        type: 'error',
      });
      return;
    }
    if (!openOrdersAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Open orders account is undefined',
        type: 'error',
      });
      return;
    }
    if (!baseCurrencyAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Open orders account is undefined',
        type: 'error',
      });
      return;
    }
    if (!quoteCurrencyAccount) {
      notify({
        message: 'Error settling funds',
        description: 'Open orders account is undefined',
        type: 'error',
      });
      return;
    }

    try {
      await settleFunds({
        market,
        openOrders: openOrdersAccount,
        connection,
        wallet,
        baseCurrencyAccount,
        quoteCurrencyAccount,
        usdcRef,
        usdtRef,
      });
    } catch (e:any) {
      notify({
        message: 'Error settling funds',
        description: e.message,
        type: 'error',
      });
    }
  }

  useInterval(() => {
    const autoSettle = async () => {
      if (
        AUTO_SETTLE_DISABLED_OVERRIDE ||
        !wallet ||
        !market ||
        !openOrdersAccount ||
        !baseCurrencyAccount ||
        !quoteCurrencyAccount ||
        !autoSettleEnabled
      ) {
        return;
      }
      if (
        !baseCurrencyBalances?.unsettled &&
        !quoteCurrencyBalances?.unsettled
      ) {
        return;
      }
      if (Date.now() - lastSettledAt < 15000) {
        return;
      }
      try {
        console.log('Settling funds...');
        setLastSettledAt(Date.now());
        await settleFunds({
          market,
          openOrders: openOrdersAccount,
          connection,
          wallet,
          baseCurrencyAccount,
          quoteCurrencyAccount,
          usdcRef,
          usdtRef,
        });
      } catch (e:any) {
        console.log('Error auto settling funds: ' + e.message);
        return;
      }
      console.log('Finished settling funds.');
    };
    connected && wallet?.autoApprove && autoSettleEnabled && autoSettle();
  }, 1000);

  const formattedBalances: [
    string | undefined,
    Balances | undefined,
    string,
    string | undefined,
  ][] = [
    [
      baseCurrency,
      baseCurrencyBalances,
      'base',
      market?.baseMintAddress.toBase58(),
    ],
    [
      quoteCurrency,
      quoteCurrencyBalances,
      'quote',
      market?.quoteMintAddress.toBase58(),
    ],
  ];


  return (
    <ContentWrapper>
        {formattedBalances.map(
        ([currency, balances, baseOrQuote, mint], index) => (
          <React.Fragment key={index}>
 <ContentDetails>
        <TopContent>
          <Heading>{currency}</Heading>
          <Hr />
          {mint && (
                <Popover
                  content={<LinkAddress address={mint} />}
                  placement="bottomRight"
                  title="Token mint"
                  trigger="hover"
                >
                 <Info src={SbInfoImg} alt="" />
                </Popover>
              )}
          
        </TopContent>
        <MiddleContent>
          <StandaloneTokenAccountsSelect 
           accounts={tokenAccounts?.filter(
            (account) => account.effectiveMint.toBase58() === mint,
          )}
          mint={mint}
          label/>
          {/* <TextContent>
            <Text>token account</Text>
            <ButtonWrapper>
              <CopyText src={CopyImg} alt="" />
              <Select
                value="aAUj...dadkj"
                style={{
                  marginRight: 8,
                  width: '107px',
                  fontSize: '12px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                }}
              >
                <Select.Option
                  style={{
                    fontSize: '12px',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    padding: '10px 16px',
                  }}
                >
                  Selent
                </Select.Option>
              </Select>
            </ButtonWrapper>
          </TextContent> */}
          <TextContent>
            <Text>wallet balance</Text>
            <Description>{balances && balances.wallet}</Description>
          </TextContent>
          <TextContent>
            <Text>UNSETTLED BALANCE</Text>
            <Description>{balances && balances.unsettled}</Description>
          </TextContent>
        </MiddleContent>
        <BottomContent>
          <Deposit onClick={() => setBaseOrQuote(baseOrQuote)} >Deposit</Deposit>
          <Settle onClick={onSettleFunds}>Settle</Settle>
        </BottomContent>
         
      </ContentDetails>
     
      {/* <AllWallLinK>
        All deposits go to your <Link external to={providerUrl}>Phantom</Link> wallet
      </AllWallLinK> */}
          </React.Fragment>
                  ),
                  )}
       
       
    </ContentWrapper>
  );
};

export default SbOneInfo;
